import React, { useState, useContext ,useEffect} from "react";
import Axios from "axios"; // Import Axios for API requests
import AuthContext from "../context/AuthContext"; // Import AuthContext for user authentication
import { API_URL } from "../components/api"; // Import API URL
import Modal from "react-bootstrap/Modal"; // Import Bootstrap Modal
import Alert from "react-bootstrap/Alert"; // Import Alert for pop-up messages
import Select from "react-select";

// Component for editing an existing ONU
function EditONU(props) {
  // Extract user information from AuthContext
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens")); // Retrieve authentication tokens from localStorage

  // State for managing form inputs and feedback messages
  const [onu_name, setOnuName] = useState(""); // State for ONU name
  const [mac_address, setMacAddress] = useState(""); // State for MAC address
  const [pon, setPon] = useState(""); // State for PON
  const [fiberCores, setFiberCores] = useState([]); // State for Fiber Cores
  const [fiberCore_id, setFiberCoreId] = useState(""); // State for Fiber Core ID]
  const [olts, setOlts] = useState([]); // State for OLTS
  const [olt_idd, setOltId] = useState(""); // State for OLT ID
  const [showSuccess, setShowSuccess] = useState(false); // State for success message visibility
  const [showError, setShowError] = useState(false); // State for error message visibility
  const [errorMessage, setErrorMessage] = useState(""); // State for error message content

  // Destructure props for easier access
  const { onu_id: id, onu_name: name, mac_address: mac, pon: Pon ,olt_id:oid,olt_name:oname,core_id:cod,code:code} = props;

  useEffect(() => {
    let isMounted = true; // Prevent updates to unmounted components
  
    // Function to fetch OLTs
    const getOlts = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/fiber/cores/olt/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });
        if (isMounted && response.status === 200) {
          setOlts(response.data); // Set OLT options
        }
      } catch (error) {
        console.error("Error fetching OLTs:", error.response || error);
      }
    };
  
    // Function to fetch Fiber Cores
    const getFiberCores = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/fiber/cores/select/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });
        if (isMounted && response.status === 200) {
          setFiberCores(response.data); // Set Fiber Core options
        }
      } catch (error) {
        console.error("Error fetching Fiber Cores:", error.response || error);
      }
    };
  
    // Fetch OLTs and Fiber Cores
    getOlts();
    getFiberCores();
  
    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);

  const oltOptions = olts.map((olt) => ({
    value: olt.olt_id,
    label: olt.olt_name,
  }));
  const fiberCoreOptions = fiberCores.map((fiberCore) => ({
    value: fiberCore.core_id,
    label: fiberCore.code,
  }));

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission

    // Make an API request to update the ONU details
    Axios.post(
      `${API_URL}/radius/fiber/cores/onu/`, // API endpoint
      {
        Action: "Put", // Action to update the ONU
        onu_id: id, // ONU ID
        onu_name: onu_name || name, // Use input value or fallback to default prop
        mac_address: mac_address || mac, // Use input value or fallback to default prop
        pon: pon || Pon, // Use input value or fallback to default prop
        olt_id:olt_idd || oid, // Use input value or fallback to default prop
        core_id: fiberCore_id || cod, // Use input value or fallback to default prop
        userdealer: user.username, // User information from AuthContext
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`, // Include authentication token
        },
      }
    )
      .then((response) => {
        console.log("ONU updated:", response); // Log success response
        setShowSuccess(true); // Show success message
        setTimeout(() => setShowSuccess(false), 3000); // Auto-hide success message after 3 seconds
      })
      .catch((error) => {
        console.error("Error updating ONU:", error); // Log error details
        setErrorMessage(
          error.response?.data?.message || "An error occurred while updating the ONU."
        ); // Set error message
        setShowError(true); // Show error message
        setTimeout(() => setShowError(false), 5000); // Auto-hide error message after 5 seconds
      });
  };

  // Validate MAC address format
  const isValidMAC = (value) => {
    const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    return macRegex.test(value);
  };

  return (
    <>
      {/* Success message */}
      {showSuccess && (
        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
          ONU updated successfully!
        </Alert>
      )}

      {/* Error message */}
      {showError && (
        <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
          {errorMessage}
        </Alert>
      )}

      {/* Modal body containing the form */}
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>ONU Name:</label>
            <input
              type="text"
              className="form-control"
              value={onu_name}
              onChange={(e) => setOnuName(e.target.value)} // Update state on input change
              placeholder={name} // Fallback to the name from props
            />
          </div>
          <div className="form-group">
            <label>MAC Address:</label>
            <input
              type="text"
              className="form-control"
              value={mac_address}
              onChange={(e) => setMacAddress(e.target.value)}
              placeholder={mac}
            />
            {!isValidMAC(mac_address) && mac_address && (
              <small className="text-danger">Invalid MAC address format.</small>
            )}
          </div>
          <div className="form-group">
            <label>PON:</label>
            <input
              type="text"
              className="form-control"
              value={pon}
              onChange={(e) => setPon(e.target.value)} // Update state on input change
              placeholder={Pon} // Fallback to the PON from props
            />
          </div>
          <div className="form-group">
          <label>OLT:</label>
          <Select
                className="search-line"
                placeholder={oname}
                options={oltOptions}
                onChange={(selectedOption) => setOltId(selectedOption?.value || "")}
              />
          </div>
          <div className="form-group">
          <label>Fiber Core:</label>
          <Select
                className="search-line"
                placeholder={code}
                options={fiberCoreOptions}
                onChange={(selectedOption) => setFiberCoreId(selectedOption?.value || "")}
              />
          </div>
          <button type="submit" className="btn btn-success" style={{ marginTop: "10px" }} disabled={mac_address && mac_address !== mac && !isValidMAC(mac_address)}>
            Save Changes
          </button>
        </form>
      </Modal.Body>
    </>
  );
}

export default EditONU; // Export the EditONU component
