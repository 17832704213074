import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { API_URL } from "../../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
import ReceiptEdit from "./ReceiptEdit";
import AuthContext from "../../context/AuthContext";
import ExcelSVG from './ExcelSVG.svg'
import * as XLSX from "xlsx";
import NewReceiptPrint from "./NewReceiptPrint";
import TextField from "@mui/material/TextField";
// For API Requests
import axios from "axios";

function ReceiptTable() {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [print, setPrint] = useState(false);
  const [showo, setShowo] = useState(false);
  const [dashoo, setDasho] = useState(false);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedReceiptId, setSelectedReceiptId] = useState(null);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [printReceipt, setPrintReceipt] = useState(false);
  const handlePrintReceipt = () => setPrintReceipt(true);
  const handleClosePrintReceipt = () => setPrintReceipt(false);

  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const [filters,setFilters] = useState({
    receipt_id:"",
    fullname_en:"",
    paid_amount_usd: "",
    paid_amount_lbp: "",
    exchange_rate: "",
    discount_lbp: "",
    discount_usd:" ",
    returned_lbp: "",
    returned_usd:" ",
    initiator:"",
    date:"",
   })
   const [showFilterModal, setShowFilterModal] = useState(false);
  const [filteredRowsData, setFilteredRowsData] = useState([]); // State to store filtered rows

  const [ClientDetails, setClientDetails] = useState({
    client_id: 0,
    receipt_id: 0,
    paid_amount_usd: 0,
    paid_amount_lbp: 0,
    exchange_rate: "",
    discount_lbp: "",
    discount_usd:" ",
    returned_lbp: "",
    returned_usd:" ",
    fullname_en: "",
  });

  const updateClientDetails = (details) => {
    setClientDetails(details);
  };
  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(`${API_URL}/inventory/shiftreceipts/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setData(response.data.reverse());
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [user.username]);

  const columns = [
    { field: "receipt_id", headerName: "ID", flex: 2, minWidth: 80 },
    { field: "fullname_en", headerName: "Client Name", flex: 2, minWidth: 200 },
    {field:"fullname_dealer",headerName:"Dealer Name",flex:2,minWidth:200},
    {
      field: "paid_amount_usd",
      headerName: "Paid USD",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "paid_amount_lbp",
      headerName: "Paid LBP",
      flex: 2,
      minWidth: 100,
    },
    { field: "exchange_rate", headerName: "Rate", flex: 2, minWidth: 100 },
    {
      field: "balance_record",
      headerName: "Balance Record",
      flex: 2,
      minWidth: 120,
    },
    {
      field: "discount_usd",
      headerName: "Discount USD",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "discount_lbp",
      headerName: "Discount LBP",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "returned_lbp",
      headerName: "Returned LBP",
      flex: 2,
      minWidth: 100,
    },
    {
      field: "returned_usd",
      headerName: "Returned USD",
      flex: 2,
      minWidth: 100,
    },
    { field: "initiator", headerName: "Cashier", flex: 2, minWidth: 100 },
    {
      field: "date",
      headerName: "Date",
      minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        date.setHours(date.getHours() + 2);
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <div>
          <Button
            variant="danger"
            onClick={() => handleOpenModal(params.row.receipt_id)}
          >
            Cancel
          </Button>
          <Button
          className="edit-client"
          onClick={() => {
            const details = {
              receipt_id: params.row.receipt_id,
            };
            updateClientDetails(details);
            handlePrintReceipt();
          }}
          >
            Print
          </Button>
          <Button
            variant="secondary"
            onClick={() => handleOpenModalRefund(params.row.receipt_id)}
          >
            Refund
          </Button>
          <button
            className="edit-client"
            onClick={() => {
              const details = {
                receipt_id: params.row.receipt_id,
                client_id: params.row.client_id,
                paid_amount_usd: params.row.paid_amount_usd,
                paid_amount_lbp: params.row.paid_amount_lbp,
                exchange_rate: params.row.exchange_rate,
                discount_lbp: params.row.discount_lbp,
                returned_lbp: params.row.returned_lbp,
                discount_usd: params.row.discount_usd,
                returned_usd:params.row.returned_usd,
                fullname_en: params.row.fullname_en,
              };
              updateClientDetails(details);
              handleShow();
            }}
          >
            Edit
          </button>
        </div>
      ),
    },
  ];

  const getRowId = (row) => row.receipt_id;

  useEffect(() => {
    // Save "searchValue" value before clearing other session storage items
    const searchValue = sessionStorage.getItem("searchReceipt");
  
    // Clear all session storage items except "searchValue"
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchReceipt") {
        sessionStorage.removeItem(key);
      }
    });
  
    // Restore "searchValue" value if needed
    if (searchValue) {
      sessionStorage.setItem("searchReceipt", searchValue);
      setSearchQuery(searchValue)
    }
  }, []);

const handleSearch = (event) => {
  const query = event.target.value;
  setSearchQuery(query);
  sessionStorage.setItem("searchReceipt", query);
};

  const handleOpenModal = (receiptId) => {
    setSelectedReceiptId(receiptId);
    setShowo(true);
  };

  const handleCloseModal = () => {
    setShowo(false);
    setSelectedReceiptId(null);
  };
  const handleOpenModalRefund = (receiptId) => {
    setSelectedReceiptId(receiptId);
    setDasho(true);
  };

  const handleCloseModalRefund = () => {
    setDasho(false);
    setSelectedReceiptId(null);
  };
  const handleConfirm = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/inventory/receipt/cancel/`,
        { receipt_id: selectedReceiptId, username: user.username },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        // Optionally, refresh the data or update the state to reflect the changes
        console.log("Receipt confirmed:", response.data);
      }
    } catch (error) {
      console.error("Error confirming receipt:", error);
    } finally {
      handleCloseModal();
      fetchData();
    }
  };
  const handleConfirmRefund = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/inventory/receipt/refund/`,
        { receipt_id: selectedReceiptId, username: user.username },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        // Optionally, refresh the data or update the state to reflect the changes
        console.log("Receipt:", response.data);
      }
    } catch (error) {
      console.error("Error confirming receipt:", error);
    } finally {
      handleCloseModalRefund();
      fetchData();
    }
  };
  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  const handleFilterChange = (filterModel) => {
    if (filterModel.items.length > 0) {
      const filteredData = data.filter((row) => {
        return filterModel.items.every((filter) => {
          const { columnField, value } = filter;
          
          // Check if row[columnField] is defined
          const cellValue = row[columnField];
          
          if (cellValue !== undefined && value !== undefined) {
            return String(cellValue)
              .toLowerCase()
              .includes(value.toLowerCase());
          }
          
          // If cellValue or value is undefined, return false to exclude this row
          return false;
        });
      });
      setFilteredRowsData(filteredData);
    } else {
      setFilteredRowsData(data); // No filters, reset to original data
    }
  };

  const columnHeaders = {
    
    fullname_en: "Full Name",
    fullname_dealer: "Full Name Dealer",
    paid_amount_usd: "Paid USD",
    paid_amount_lbp: "Paid LBP",
    exchange_rate: "Rate",
    balance_record:"Balance Record",
    discount_usd:"Discount USD",
    discount_lbp:"Discount LBP",
    returned_lbp:"Returned LBP",
    initiator:"Cashier",
    date:"Date",

  };
  // Function to transform the rows based on columnHeaders
const transformRowsForExport = (rows) => {
  return rows.map((row) => {
    const transformedRow = {};
    Object.keys(columnHeaders).forEach((key) => {
      transformedRow[columnHeaders[key]] = row[key];
    });
    return transformedRow;
  });
};

const handleExportToExcel = () => {
  // Transform the rows to match the new column headers
  const transformedRows = filteredRowsData.length > 0 
      ? transformRowsForExport(filteredRowsData) 
      : transformRowsForExport(filteredRows);

  const worksheet = XLSX.utils.json_to_sheet(transformedRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Receipts");
  XLSX.writeFile(workbook, "Receipts.xlsx");
};

const [isPressed, setIsPressed] = useState(false);

  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel();
    // Optional: Reset the button state after a short delay
    setTimeout(() => {
      setIsPressed(false);
    }, 200); // Adjust the timeout as needed
  };

  const sumField = (field) => {
    return filteredRows.reduce((total, row) => {
      const value = parseFloat(row[field]);
      return isNaN(value) ? total : total + value;
    }, 0);
  };

  const sumPaidUsd = sumField("paid_amount_usd");
  const sumPaidLbp = sumField("paid_amount_lbp");
  const sumReturnedUsd = sumField("returned_usd");
  const sumReturnedLbp = sumField("returned_lbp");

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick}
        variant="outline-success"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 10px',
          fontSize: '14px',
          border: 'none',
          backgroundColor: isPressed ? 'white' : 'transparent', // Change background on press
          color: isPressed ? 'black' : '#007bff', // Change text color on press
          cursor: 'pointer',
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: '20px',
            marginRight: '8px',
            filter: isPressed ? 'invert(0)' : 'invert(1)', // Make the SVG white or retain its original color
          }}
        />
        Excel
      </Button>
      <Button
        onClick={() => setShowFilterModal(true)}
        variant="outline-primary"
      >Filter</Button>
    </GridToolbarContainer>
  );

  const CustomFooter = () => (
    <GridFooterContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px",
          color: "white",
        }}
      >
        <div>Paid USD: {sumPaidUsd.toFixed(2)}</div>
        <div>Paid LBP: {sumPaidLbp.toFixed(2)}</div>
        <div>Returned USD: {sumReturnedUsd.toFixed(2)}</div>
        <div>Returned LBP: {sumReturnedLbp.toFixed(2)}</div>
        <GridPagination />
      </div>
    </GridFooterContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            onFilterModelChange={handleFilterChange}
            components={{
              Toolbar: CustomToolbar,
              Footer: CustomFooter,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={showo} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this receipt?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={printReceipt} onHide={handleClosePrintReceipt}>
        <Modal.Header closeButton>
          <Modal.Title>Print</Modal.Title>
        </Modal.Header>
        <Modal.Body><NewReceiptPrint receipt_id={ClientDetails.receipt_id} /></Modal.Body>
        
      </Modal>
      <Modal show={dashoo} onHide={handleCloseModalRefund}>
        <Modal.Header closeButton>
          <Modal.Title>Refund Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to refund this receipt?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModalRefund}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmRefund}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit Receipt</Modal.Title>
        </Modal.Header>
        <div className="forma">
          <ReceiptEdit
            receiptId={ClientDetails.receipt_id}
            client_id={ClientDetails.client_id}
            paid_amount_usd={ClientDetails.paid_amount_usd}
            paid_amount_lbp={ClientDetails.paid_amount_lbp}
            exchange_rate={ClientDetails.exchange_rate}
            discount_lbp={ClientDetails.discount_lbp}
            returned_lbp={ClientDetails.returned_lbp}
            returned_usd={ClientDetails.returned_usd}
            discount_usd={ClientDetails.discount_usd}
            fullname_en={ClientDetails.fullname_en}
          />
        </div>
      </Modal>
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {columns
            .filter((col) => col.field !== "actions")
            .map((col) => (
              <div key={col.field} className="filter">
                <label>{col.headerName}</label>
                <input
                  type="text"
                  placeholder={`Filter by ${col.headerName}`}
                  value={filters[col.field] || ""}
                  onChange={(e) => handleFilterChange(col.field, e.target.value)}
                  className="filter-input"
                />
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFilterModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ReceiptTable;
