import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap
import "./Clients.css";
import EditOrder from "./InvoiceDetail"; // Import EditOrder component
import { Modal, Button } from "react-bootstrap"; // Import Modal and Button from react-bootstrap

function DealerInvoiceTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [expandedRow, setExpandedRow] = useState(null); // Track the expanded row (for modal)
  const [showModal, setShowModal] = useState(false); // Track modal visibility

  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  //fetches the invoices of the dealers alognside their products and services
  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_URL}/inventory/order/total/dealer/services/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
        },
      });

      if (response.status === 200) {
        setData(response.data.reverse());
      } else if (response.status === 403) {
        console.error("Access denied or invalid dealer invoices.");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  //it runs the fetchData() function with user.username and authTokens.access acting as dependency
  useEffect(() => {
    fetchData();
  }, [user.username, authTokens.access]);

  //columns of the dealer invoice table
  const columns = [
    { field: "invoice_id", headerName: "Invoice ID", flex: 2, minWidth: 100, headerClassName: 'header-cell' },
    { field: "fullname_en", headerName: "Name", flex: 2, minWidth: 150, headerClassName: 'header-cell' },
    { field: "date", headerName: "Date", flex: 2, minWidth: 150, headerClassName: 'header-cell' },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: 'header-cell',
      renderCell: (params) => (
        <button
          className="edit-client"
          onClick={() => handleEditClick(params.row.invoice_id)}
        >
          Show
        </button>
      ),
    },
  ];
  
  // gets the id of the rows
  const getRowId = (row) => row.invoice_id;

  useEffect(() => {
    // Save "searchValue" value before clearing other session storage items
    const searchValue = sessionStorage.getItem("searchDealerInvoice");
  
    // Clear all session storage items except "searchValue"
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchDealerInvoice") {
        sessionStorage.removeItem(key);
      }
    });
  
    // Restore "searchValue" value if needed
    if (searchValue) {
      sessionStorage.setItem("searchDealerInvoice", searchValue);
      setSearchQuery(searchValue)
    }
  }, []);
  
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    sessionStorage.setItem("searchDealerInvoice", query);
  };

  //handles the modals highligting the details of the invoice
  const handleEditClick = (invoiceId) => {
    setExpandedRow(invoiceId); // Set the selected invoice ID
    setShowModal(true); // Show the modal
  };

  //handles the closure of the modals
  const handleCloseModal = () => {
    setShowModal(false); // Close the modal
    setExpandedRow(null); // Clear the expanded row
  };

  //handles the filtering of the row in searching
  const filteredRows = data.filter((row) => {
    const searchWords = searchQuery.split(" ");
    return searchWords.every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );
  });

  //Custom toolbar
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  );

  return (
    <div className="Clients">
      <div className="main">
      {/* this render handles the search query*/}
        <div className="DataGridContainer">
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearch}
            className="search-client"
          />
          {!loading && data.length > 0 && (
            <div className="ClientTable" style={{ height: 520 ,display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
            <DataGrid
              rows={filteredRows}
              columns={columns}
              getRowId={getRowId}
              components={{
              Toolbar: CustomToolbar,
              }}
              rowsPerPageOptions={[10, 100, 1000]}
              pagination
              disableSelectionOnClick
              sx={{
              border: 'none', // Remove outer border
              '& .MuiDataGrid-columnHeader': {
              border: 'none', // Remove column header borders
              color: 'white', // Set column header font color to white
              backgroundColor: 'rgb(30, 30, 30)', // Set background color for header
            },
            '& .MuiDataGrid-cell': {
            color: 'white', // Set cell font color to white
            },
            '& .MuiDataGrid-footerCell': {
            color: 'white', // Set footer font color to white
            },
            '& .MuiDataGrid-row:nth-of-type(odd)': {
            backgroundColor: 'rgb(18, 38, 50)', // Background color for odd rows
            },
            '& .MuiDataGrid-row:nth-of-type(even)': {
            backgroundColor: 'rgb(50, 65, 80)', // Background color for even rows (change as needed)
            },
          }}
          />

          
            </div>
          )}
        </div>

        {/* Bootstrap Modal to show EditOrder component */}
        <Modal show={showModal} onHide={handleCloseModal} centered>
          <Modal.Header closeButton>
            <Modal.Title>Invoice Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Render EditOrder with the selected invoice_id */}
            {expandedRow && <EditOrder invoice_id={expandedRow} />}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default DealerInvoiceTable;
