// Sidebar.js
import React, { useState, useEffect, useContext, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTachometerAlt,
  faUsers,
  faUser,
  faWifi,
  faList,
  faCog,
  faBox,
  faFile,
  faMobile,
  faMoneyBill,
  faHandshake,
  faPiggyBank,
  faWarehouse,
  faFileInvoice,
  faRectangleList,
  faFileInvoiceDollar,
  faHands,
  faCreditCard,
} from "@fortawesome/free-solid-svg-icons"; // Import the Font Awesome icons you need
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";
import { NavLink } from "react-router-dom";
import axios from "axios";
import "./sidebar.css";
import Wizard from "./wizard";
import Shift from "./shift";
import Shiftend from "./shiftend";

import Modal from "react-bootstrap/Modal";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import logoImage from "./oss-logo.svg"; // Import your logo image

const Sidebar = ({ isOpen, closeSidebar }) => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [shift, setShift] = useState("");

  const sidebarRef = useRef(null);
  console.log(data);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [shiftshow, setShowshift] = useState(false);
  const handleCloseshift = () => setShowshift(false);
  const handleShowshift = () => setShowshift(true);

  const [shiftshowend, setShowshiftend] = useState(false);
  const handleCloseshiftend = () => setShowshiftend(false);
  const handleShowshiftend = () => setShowshiftend(true);
  const handleNavLinkClick = () => {
    // Close the sidebar after 2 seconds
    setTimeout(() => {
      closeSidebar();
    }, 2000);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };
    const fetchShift = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/inventory/shift/status/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setShift(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
    fetchShift();

    // Add a click event listener to the document to close the sidebar when clicking outside of it
    const handleOutsideClick = (e) => {
      if (
        isOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(e.target)
      ) {
        closeSidebar();
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      // Remove the event listener when the component unmounts
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [user.username, isOpen, closeSidebar]);

  return (
    <div className={`sidebar ${isOpen ? "open" : ""}`} ref={sidebarRef}>
      <div className="sidebar-content">
        <img
          src={logoImage}
          alt="Logo"
          style={{ width: "130px", marginLeft: "35px", marginBottom: "30px" }}
        />{" "}
        {/* Set width to 200px */}
        <div className="nav-links">
          <NavLink
            exact
            to="/"
            className="nav-link"
            activeClassName="active"
            onClick={handleNavLinkClick}
          >
            <FontAwesomeIcon
              icon={faTachometerAlt}
              style={{ marginRight: "13px" }}
            />{" "}
            Dashboard
          </NavLink>
          <NavLink
            exact
            to="/clients"
            className="nav-link"
            activeClassName="active"
            onClick={handleNavLinkClick}
          >
            <FontAwesomeIcon icon={faUsers} style={{ marginRight: "10px" }} />{" "}
            Clients
          </NavLink>
          {data === "yes" && (
            <NavLink
              exact
              to="/internet/dealers"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "13px" }} />
              Resellers
            </NavLink>
          )}
          {data === "yes" && (
            <NavLink
              exact
              to="/internet/dealers/testing"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "13px" }} />
              Testing Resellers
            </NavLink>
          )}

          {data === "Dealer" && (
            <NavLink
              exact
              to="/plans/resellers"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />{" "}
              Internet Plans
            </NavLink>
          )}

          {(data === "yes" || data === "Reseller") && (
            <NavLink
              exact
              to="/plans/internet"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faWifi} style={{ marginRight: "10px" }} />{" "}
              Internet Plans
            </NavLink>
          )}
          {data === "Reseller" && (
            <NavLink
              exact
              to="/resellers"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />{" "}
              Resellers
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              to="/subscriptions/internet/osss"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "10px" }} />{" "}
              Subscriptions
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              to="/subscriptions/internet/free"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "10px" }} />{" "}
              Free Subscriptions
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              to="/issues/all/"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "10px" }} />{" "}
              Issues
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              to="/labels"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "10px" }} />{" "}
              Labels
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              to="/fiber/cores"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "10px" }} />{" "}
              Fiber Cores
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              to="/wireless"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "10px" }} />{" "}
              Wireless Access Points
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <FontAwesomeIcon
                  icon={faMoneyBill}
                  style={{ marginRight: "13px" }}
                />
                Accounting
              </AccordionSummary>
              <AccordionDetails>
                {shift ? (
                  <button onClick={handleShowshiftend} className="AddClient">
                    <i className="fa fa-user-plus" aria-hidden="true"></i> End
                    Shift
                  </button>
                ) : (
                  <button onClick={handleShowshift} className="AddClient">
                    <i className="fa fa-user-plus" aria-hidden="true"></i> Start
                    Shift
                  </button>
                )}
              </AccordionDetails>
              <AccordionDetails>
                <NavLink
                  exact
                  to="/accounting/collection"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleNavLinkClick}
                >
                  <FontAwesomeIcon
                    icon={faPiggyBank}
                    style={{ marginRight: "13px" }}
                  />
                  Collection
                </NavLink>
              </AccordionDetails>
              <AccordionDetails>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FontAwesomeIcon
                      icon={faCreditCard}
                      style={{ marginRight: "13px" }}
                    />
                    Orders
                  </AccordionSummary>
                  <AccordionDetails>
                    <NavLink
                      exact
                      to="/accounting/order"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleNavLinkClick}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "13px" }}
                      />
                      Details
                    </NavLink>
                  </AccordionDetails>
                  <AccordionDetails>
                    <NavLink
                      exact
                      to="/accounting/order/form"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleNavLinkClick}
                    >
                      <FontAwesomeIcon
                        icon={faTachometerAlt}
                        style={{ marginRight: "13px" }}
                      />
                      Add Order
                    </NavLink>
                  </AccordionDetails>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FontAwesomeIcon
                      icon={faCreditCard}
                      style={{ marginRight: "13px" }}
                    />
                    Reseller Orders
                  </AccordionSummary>
                  <AccordionDetails>
                    <NavLink
                      exact
                      to="/accounting/order/dealer"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleNavLinkClick}
                    >
                      <FontAwesomeIcon
                        icon={faList}
                        style={{ marginRight: "13px" }}
                      />
                      Details
                    </NavLink>
                  </AccordionDetails>
                  <AccordionDetails>
                    <NavLink
                      exact
                      to="/accounting/order/form/dealer"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleNavLinkClick}
                    >
                      <FontAwesomeIcon
                        icon={faTachometerAlt}
                        style={{ marginRight: "13px" }}
                      />
                      Add Order
                    </NavLink>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>{" "}
              <AccordionDetails>
                <NavLink
                  exact
                  to="/accounting/quotation"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleNavLinkClick}
                >
                  <FontAwesomeIcon
                    icon={faFileInvoice}
                    style={{ marginRight: "13px" }}
                  />
                  Quotations
                </NavLink>
              </AccordionDetails>
              <AccordionDetails>
                <NavLink
                  exact
                  to="/accounting/invoice"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleNavLinkClick}
                >
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    style={{ marginRight: "13px" }}
                  />
                  Invoices
                </NavLink>
                <NavLink
                  exact
                  to="/accounting/invoice/system"
                  className="nav-link"
                  activeClassName="active"
                  onClick={handleNavLinkClick}
                >
                  <FontAwesomeIcon
                    icon={faFileInvoiceDollar}
                    style={{ marginRight: "13px" }}
                  />
                  Sys Invoices
                </NavLink>
              </AccordionDetails>
              <AccordionDetails>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FontAwesomeIcon
                      icon={faCreditCard}
                      style={{ marginRight: "13px" }}
                    />
                    Reporting
                  </AccordionSummary>
                  <AccordionDetails>
                    <NavLink
                      exact
                      to="/accounting/dashboardossss"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleNavLinkClick}
                    >
                      <FontAwesomeIcon
                        icon={faTachometerAlt}
                        style={{ marginRight: "13px" }}
                      />
                      Dashboard
                    </NavLink>
                  </AccordionDetails>
                  <AccordionDetails>
                    <NavLink
                      exact
                      to="/accounting/drawersos"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleNavLinkClick}
                    >
                      <FontAwesomeIcon
                        icon={faBox}
                        style={{ marginRight: "13px" }}
                      />
                      Drawers
                    </NavLink>
                  </AccordionDetails>
                  <AccordionDetails>
                    <NavLink
                      exact
                      to="/accounting/receipts"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleNavLinkClick}
                    >
                      <FontAwesomeIcon
                        icon={faFileInvoice}
                        style={{ marginRight: "13px" }}
                      />
                      Receipts
                    </NavLink>
                  </AccordionDetails>
                  <AccordionDetails>
                    <NavLink
                      exact
                      to="/accounting/shift/receipts"
                      className="nav-link"
                      activeClassName="active"
                      onClick={handleNavLinkClick}
                    >
                      <FontAwesomeIcon
                        icon={faFileInvoice}
                        style={{ marginRight: "13px" }}
                      />
                      Shift Receipts
                    </NavLink>
                  </AccordionDetails>
                </Accordion>
              </AccordionDetails>
            </Accordion>
          )}

          {(data === "Reseller" || data === "Read") && (
            <NavLink
              to="/subscriptions/internet"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "13px" }} />{" "}
              Subscriptions
            </NavLink>
          )}
          {data === "Dealer" && (
            <NavLink
              to="/subscriptions/internet/reseller"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "13px" }} />{" "}
              Subscriptions
            </NavLink>
          )}
          {data === "Commission" && (
            <NavLink
              to="/subscriptions/internet/osss/commission"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faList} style={{ marginRight: "13px" }} />{" "}
              Subscriptions
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              to="/subscriptions/internet/osss/services"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon
                icon={faRectangleList}
                style={{ marginRight: "10px" }}
              />{" "}
              Sub Services
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              to="/onboarding"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faHands} style={{ marginRight: "10px" }} />{" "}
              Onboarding
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              to="/inventory"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon
                icon={faWarehouse}
                style={{ marginRight: "10px" }}
              />{" "}
              Inventory
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              to="/mobile"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon
                icon={faMobile}
                style={{ marginRight: "10px" }}
              />{" "}
              Mobile
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              to="/requests"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon
                icon={faHandshake}
                style={{ marginRight: "10px" }}
              />{" "}
              Requests
            </NavLink>
          )}
          {(data === "yes" || data === "OSS") && (
            <NavLink
              exact
              to="/internet/actions"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faUser} style={{ marginRight: "13px" }} />{" "}
              Action Log
            </NavLink>
          )}
          {data === "yes" && (
            <NavLink
              exact
              to="/subscriptions/internet/deleted"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faUser} style={{ marginRight: "10px" }} />{" "}
              Deleted
            </NavLink>
          )}
          {(data === "Dealer" || data === "Reseller") && (
            <NavLink
              to="/admin/console"
              className="nav-link"
              activeClassName="active"
              onClick={handleNavLinkClick}
            >
              <FontAwesomeIcon icon={faCog} style={{ marginRight: "10px" }} />{" "}
              Accounting
            </NavLink>
          )}

          <button onClick={handleShow} className="AddClient">
            <i className="fa fa-user-plus" aria-hidden="true"></i> Add
          </button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add</Modal.Title>
        </Modal.Header>
        <Wizard closeModal={handleClose} />
      </Modal>
      <Modal show={shiftshow} onHide={handleCloseshift} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add Shift</Modal.Title>
        </Modal.Header>
        <Shift closeModal={handleCloseshift} />
      </Modal>
      <Modal show={shiftshowend} onHide={handleCloseshiftend} centered>
        <Modal.Header closeButton>
          <Modal.Title>End Shift</Modal.Title>
        </Modal.Header>
        <Shiftend closeModal={handleCloseshiftend} />
      </Modal>
    </div>
  );
};

export default Sidebar;
