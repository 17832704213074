import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import Accountingtb from "./accountingtb";
import PocketExchange from "./newPocketExchange";
import Exchange from "./exchange";
import ResellerCollect from "./ResellerCollect";
import "./accounting.css";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../components/api";
import { NavLink } from "react-router-dom";
import Payment from "./payment";

function Accounting() {
  const { user } = useContext(AuthContext);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [balanceDrawerUSD, setBalanceDrawerUSD] = useState(0);
  const [balanceDrawerLBP, setBalanceDrawerLBP] = useState(0);
  const [drawerName, setDrawerName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPocket, setShowPocket] = useState(false);
  const handleClosePocket = () => setShowPocket(false);
  const handleShowPocket = () => setShowPocket(true);
  const [showResellerCollect, setShowResellerCollect] = useState(false);
  const handleCloseResellerCollect = () => setShowResellerCollect(false);
  const handleShowResellerCollect = () => setShowResellerCollect(true);

  const [showPayment, setShowPayment] = useState(false);
  const handleClosePayment = () => setShowPayment(false);
  const handleShowPayment = () => setShowPayment(true);

  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(
        `${API_URL}/inventory/pocket/exchange/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        }
      );

      if (response.status === 200) {
        const data = response.data;
        setBalanceDrawerUSD(data.smallest_drawer.balance_usd);
        setBalanceDrawerLBP(data.smallest_drawer.balance_lbp);
        setDrawerName(data.smallest_drawer.drawer_name);
        setErrorMessage(""); // Clear any previous error message
        console.log(data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, [user.username]);
  return (
    <div className="accounting">
      <div className="accounting-top">
        <button onClick={handleShow} className="AddClient">
          Drawer Exchange
        </button>
        <button onClick={handleShowPocket} className="AddClient">
          Pocket Exchange
        </button>
        <button onClick={handleShowResellerCollect} className="AddClient">
          Reseller Payment
        </button>
        <button onClick={handleShowPayment} className="AddClient">
          Payment
        </button>
        <NavLink
          exact
          to="/accounting/report/monthly"
          activeClassName="active"
          className="AddClient"
          style={{ textDecoration: "none" }}
        >
          Monthly Report
        </NavLink>{" "}
        <h4 className="drawer">{drawerName}</h4>
        <h4 className="drawer">Balance USD {balanceDrawerUSD}</h4>
        <h4 className="drawer">Balance LBP {balanceDrawerLBP}</h4>
      </div>
      <div className="main_accounting">
        <Accountingtb fetchDrawer={fetchData} />
      </div>
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Exchange</Modal.Title>
          </Modal.Header>
          <Exchange />
        </div>
      </Modal>
      <Modal show={showPocket} onHide={handleClosePocket} className="modal-lg">
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Pocket Money Exchange</Modal.Title>
          </Modal.Header>
          <PocketExchange />
        </div>
      </Modal>
      <Modal
        show={showResellerCollect}
        onHide={handleCloseResellerCollect}
        className="modal-lg"
      >
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Reseller Collect</Modal.Title>
          </Modal.Header>
          <ResellerCollect
            handleClose={handleCloseResellerCollect}
            closeDrawer={fetchData}
          />
        </div>
      </Modal>
      <Modal
        show={showPayment}
        onHide={handleClosePayment}
        className="modal-lg"
      >
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Payment</Modal.Title>
          </Modal.Header>
          <Payment />
        </div>
      </Modal>
    </div>
  );
}

export default Accounting;
