import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../../components/api";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function EditingInvoices({ invoiceId }) {
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [totalAmount, setTotalAmount] = useState("");
  const [discount, setDiscount] = useState("");
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(`${API_URL}/inventory/invoice/system/individual/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          username: user.username,
          invoice_id: invoiceId,
        },
      });
      if (response.status === 200) {
        console.log("Username:", user.username);
        console.log("Invoice ID:", invoiceId);
        setInvoices(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
  };

  useEffect(() => {
    fetchInvoices();
  }, [user.username, authTokens.access]);

  const handleEditClick = (invoice) => {
    setSelectedInvoice(invoice);
    setTotalAmount(invoice.total_amount);
    setDiscount(invoice.discount);
    setShowEditModal(true);
  };

  const handleDiscountChange = (e) => {
    const value = parseFloat(e.target.value);
    const total = parseFloat(totalAmount);
    
    // If the input is empty, allow it
    if (e.target.value === '') {
      setDiscount('');
      return;
    }
    
    // Validate that the input is a number and not negative
    if (isNaN(value) || value < 0) {
      return;
    }
    
    // Ensure discount doesn't exceed total amount
    if (value <= total) {
      setDiscount(value);
    } else {
      setDiscount(total);
    }
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/inventory/invoices/`,
        {
          invoice_id: invoiceId,
          total_amount: totalAmount,
          discount: discount,
          userdealer: user.username,
          note:"-",
          Action: "Put",
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        fetchInvoices();
        setShowEditModal(false);
      }
    } catch (error) {
      console.error("Error updating invoice:", error);
    }
  };

  return (
    <div>
      <h2>Edit Invoices</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Invoice ID</th>
            <th>Total Amount</th>
            <th>Discount</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice) => (
            <tr key={invoice.invoice_id}>
              <td>{invoice.invoice_id}</td>
              <td>{invoice.total_amount}</td>
              <td>{invoice.discount}</td>
              <td>
                <Button
                  variant="primary"
                  onClick={() => handleEditClick(invoice)}
                >
                  Edit
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="form-group">
              <label>Total Amount</label>
              <input
                type="number"
                className="form-control"
                value={totalAmount}
                onChange={(e) => setTotalAmount(e.target.value)}
                readOnly
              />
            </div>
            <div className="form-group">
              <label>Discount</label>
              <input
                type="number"
                className="form-control"
                value={discount}
                onChange={handleDiscountChange}
                min="0"
                max={totalAmount}
                step="0.01"
              />
              <small className="text-muted">
                Maximum discount allowed: {totalAmount}
              </small>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEditModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default EditingInvoices;