import React, { useState, useEffect, useContext } from "react";
import { Form, Button, Row, Col, Alert } from "react-bootstrap";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../components/api";

function PocketExchange() {
    const { user } = useContext(AuthContext);
    //these are the use states of amount ,currency and action with its default state/value
    const [amount, setAmount] = useState(0);
    const [currency, setCurrency] = useState("USD");
    const [action, setAction] = useState("withdraw");
  
    //these are the used states of the balance of the drawer of the current user
    const [balanceDrawerUSD, setBalanceDrawerUSD] = useState(0.0);
    const [balanceDrawerLBP, setBalanceDrawerLBP] = useState(0.0);
    const [balancePocketMoneyUSD, setBalancePocketMoneyUSD] = useState(0.0);
    const [balancePocketMoneyLBP, setBalancePocketMoneyLBP] = useState(0.0);
  
    const [drawerId, setDrawerId] = useState(0);
    const [drawerName, setDrawerName] = useState("");
    const [employeeDrawers, setEmployeeDrawers] = useState([]);
    const [pocketMoneyDrawer, setPocketMoneyDrawer] = useState(null);
    const [selectedDrawer, setSelectedDrawer] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [exchangeRate, setExchangeRate] = useState(90000); // Default value or fetched dynamically
    const [isButtonDisabled, setIsButtonDisabled] = useState(false); // State for button disable
  
  
   // Fetch data and populate the drawer of the user and pocket money
  const fetchData = async () => {
    try {
      const authTokens = JSON.parse(localStorage.getItem("authTokens"));
      const response = await axios.get(
        `${API_URL}/inventory/pocket/exchange/new/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        }
      );
  
      if (response.status === 200) {
        const data = response.data;
  
        // Set initial balances and drawer information//set the balance of pocket money and current user drawer
        setBalancePocketMoneyUSD(data.drawer_1_balance.balance_usd || 0.0);
        setBalancePocketMoneyLBP(data.drawer_1_balance.balance_lbp || 0.0);
        setEmployeeDrawers(data.employee_drawers || []); // Populate the employee drawers list
  
        // Find and set the Pocket Money drawer
        const pocketMoney = data.pocket_money_drawer || {};
        setPocketMoneyDrawer(pocketMoney);
        
        //find and set the current user drawer
        const initialDrawer = data.employee_drawers[0] || {};
        setSelectedDrawer(initialDrawer.drawer_id || null);
        setBalanceDrawerUSD(initialDrawer.balance_usd || 0.0);
        setBalanceDrawerLBP(initialDrawer.balance_lbp || 0.0);
  
        setErrorMessage("");
        setSuccessMessage("");
      }
    } catch (error) {
      console.error("Fetch Error:", error);
      setErrorMessage("Error fetching data. Please check your network or server.");
    }
  };
  
  // Fetch the data when the component mounts
  useEffect(() => {
    fetchData();
  }, [user.username]);
  
  // Update drawer balances when a different drawer is selected
  useEffect(() => {
    if (selectedDrawer !== null) {
      const selected = employeeDrawers.find(
        (drawer) => drawer.drawer_id === selectedDrawer
      );
  
      if (selected) {
        // Update drawer balances based on the selected drawer
        setBalanceDrawerUSD(selected.balance_usd);
        setBalanceDrawerLBP(selected.balance_lbp);
        setDrawerName(selected.name);
      }
    }
  }, [selectedDrawer, employeeDrawers]);
  
  //this functions handles the money transfer/currency exchange of pocket money and drawer
  const handleTransfer = async () => {
    console.log("Handle transfer started");

    let sufficientBalance = false;
    let exchangedValue = 0.0;

    console.log("Action:", action);
    console.log("Currency:", currency);
    console.log("Amount:", amount);
    console.log("Exchange Rate:", exchangeRate);

    if (action === "withdraw") {
        if (currency === "USD") {
            exchangedValue = amount;
            sufficientBalance = balancePocketMoneyUSD >= amount;
        } else if (currency === "LBP") {
            exchangedValue = amount / exchangeRate;
            sufficientBalance = balancePocketMoneyLBP >= amount;
        }
    } else if (action === "deposit") {
        if (currency === "USD") {
            exchangedValue = amount;
            sufficientBalance = balanceDrawerUSD >= amount;
        } else if (currency === "LBP") {
            exchangedValue = amount * exchangeRate;
            sufficientBalance = balanceDrawerLBP >= amount;
        }
    }

    console.log("Sufficient balance:", sufficientBalance);

    if (!sufficientBalance) {
        setErrorMessage("Not sufficient account balance.");
        setSuccessMessage("");
        console.log("Not sufficient balance, exiting...");
        return;
    }

    try {
        setIsButtonDisabled(true); // Disable button immediately

        console.log("Sending data to the backend:", {
            username: user.username,
            amount: amount,
            currency: currency,
            drawer_id: selectedDrawer,
            action: action,
            exchange_rate: exchangeRate,
        });

        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.post(`${API_URL}/inventory/pocket/exchange/new/`, {
            username: user.username,
            amount: amount,
            currency: currency,
            drawer_id: selectedDrawer,
            action: action,
            exchange_rate: exchangeRate,
        }, {
            headers: {
                Authorization: `Bearer ${authTokens.access}`,
            },
        });

        console.log("Response from backend:", response.data);
        setSuccessMessage(`${action === "withdraw" ? "Withdrawn" : "Deposited"} ${amount} ${currency} successfully!`);

        // Delay balance updates by 5 seconds
        setTimeout(() => {
            console.log("Updating balances after delay...");

            if (action === "withdraw") {
                setBalancePocketMoneyUSD((prev) => prev - (currency === "USD" ? amount : 0.0));
                setBalancePocketMoneyLBP((prev) => prev - (currency === "LBP" ? amount : 0.0));
                setBalanceDrawerUSD((prev) => prev + (currency === "USD" ? amount : 0.0));
                setBalanceDrawerLBP((prev) => prev - (currency === "LBP" ? exchangedValue : 0.0));
            } else if (action === "deposit") {
                setBalanceDrawerUSD((prev) => prev - (currency === "USD" ? amount : 0.0));
                setBalanceDrawerLBP((prev) => prev + (currency === "LBP" ? amount : 0.0));
                setBalancePocketMoneyUSD((prev) => prev + (currency === "USD" ? amount : 0.0));
                setBalancePocketMoneyLBP((prev) => prev - (currency === "LBP" ? amount : 0.0));
            }

            fetchData(); // Refresh data after update
            setIsButtonDisabled(false); // Re-enable button after 5 seconds
        }, 5000);

        setErrorMessage(""); // Clear error message if transaction is successful
    } catch (error) {
        console.error("Error during transaction:", error);
        setErrorMessage("Transaction failed.");
        setIsButtonDisabled(false); // Re-enable button in case of failure
    }
};
  
  
  
  
    return (
      <div className="container mt-5">
        <h2 className="mb-4">Pocket Money Exchange</h2>
        {/*These print out success or error messages */}
        {successMessage && <Alert variant="success">{successMessage}</Alert>}
        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
        <Row>
          <Col md={6}>
            <Form>
            {/*This form group is a select input of actions which is either withdrawal or deposit */}
              <Form.Group as={Row} controlId="formAction" style={{marginBottom:"10px"}}>
                <Form.Label column sm="4">Action</Form.Label>
                <Col sm="8">
                  <Form.Control
                    as="select"
                    value={action}
                    onChange={(e) => setAction(e.target.value)}
                  >
                    <option value="withdraw">Withdraw from Pocket Money</option>
                    <option value="deposit">Deposit to Pocket Money</option>
                  </Form.Control>
                </Col>
              </Form.Group>
  
               {/*This form group is a select input of currency which is either usd or lbp */}
              <Form.Group as={Row} controlId="formCurrency" style={{marginBottom:"10px",marginTop:"10px"}}>
                <Form.Label column sm="4">Currency</Form.Label>
                <Col sm="8">
                  <Form.Control
                    as="select"
                    value={currency}
                    onChange={(e) => setCurrency(e.target.value)}
                  >
                    <option value="USD">USD</option>
                    <option value="LBP">LBP</option>
                  </Form.Control>
                </Col>
              </Form.Group>
  
              {/* Drawer Selection with Dynamic Options, here this selects the drawer of the current user */}
              <Form.Group as={Row} controlId="formDrawer" style={{marginBottom:"10px",marginTop:"10px"}}>
                <Form.Label column sm="4">Drawer</Form.Label>
                <Col sm="8">
                  <Form.Control
                    as="select"
                    value={selectedDrawer || ""}
                    onChange={(e) => {
                      const selectedId = parseInt(e.target.value);
                      setSelectedDrawer(selectedId);
                    }}
                  >
                    <option value="" disabled>Select a drawer</option>
                    {employeeDrawers.map((drawer) => (
                      <option key={drawer.drawer_id} value={drawer.drawer_id}>
                        {drawer.drawer_name}
                      </option>
                    ))}
                  </Form.Control>
                </Col>
              </Form.Group>
              {/*This sets the exchange rate */}
              <Form.Group as={Row} controlId="formExchangeRate" style={{ marginBottom: "10px", marginTop: "10px" }}>
                <Form.Label column sm="4">Exchange Rate</Form.Label>
                <Col sm="8">
                <Form.Control
                  type="number"
                  value={exchangeRate}
                  min={0}
                  onChange={(e) => setExchangeRate(parseFloat(e.target.value) || 0)}
                  />
                </Col>
                </Form.Group>
                {/*This set the amount */}
              <Form.Group as={Row} controlId="formAmount" style={{marginTop:"10px"}}>
                <Form.Label column sm="4">Amount</Form.Label>
                <Col sm="8">
                  <Form.Control
                    type="number"
                    value={amount}
                    min={0}
                    onChange={(e) => setAmount(parseFloat(e.target.value) || 0)}
                  />
                </Col>
              </Form.Group>
  
              <Button
                variant="primary"
                onClick={handleTransfer}
                style={{marginTop:"12px",marginInlineStart:"87px"}}
              >
                Execute Transaction
              </Button>
            </Form>
          </Col>
          <Col md={6}>
            <h5>Balances</h5>
            <p>Selected Drawer Balance USD: {balanceDrawerUSD}</p>
            <p>Selected Drawer Balance LBP: {balanceDrawerLBP}</p>
            <p>Pocket Money Balance USD: {balancePocketMoneyUSD}</p>
            <p>Pocket Money Balance LBP: {balancePocketMoneyLBP}</p>
          </Col>
        </Row>
      </div>
    );
  }
  
  export default PocketExchange;
