import React, { useState, useEffect, useContext } from "react";
import ReceiptTable from "./ReceiptsTable";
import Modal from "react-bootstrap/Modal";
import PrintReceipt from "./ReceiptPrint";
import AuthContext from "../../context/AuthContext";
import axios from "axios";
import { API_URL } from "../../components/api";

function ShiftReceipts() {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/administration/groups/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200) {
          setData(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [user.username]);
  return (
    <div className="Plans">
      <div className="main">
        {/*<button onClick={handleShow} className="AddClient">
          Create Receipt
        </button>*/}
        <ReceiptTable />
      </div>
      <Modal show={show} onHide={handleClose}>
        <div className="forma">
          <Modal.Header closeButton>
            <Modal.Title>Add Plan</Modal.Title>
          </Modal.Header>
          <PrintReceipt />
        </div>
      </Modal>
    </div>
  );
}

export default ShiftReceipts;
