import React, { useState, useEffect, useContext, useCallback } from "react";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import "./discounts.css";

function Discount(props) {
  const [users, setUsers] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [discountAmount, setDiscountAmount] = useState("");
  const [discountPercentage, setDiscountPercentage] = useState("");
  const [discountDescription, setDiscountDescription] = useState("");
  const [username, setUsername] = useState("");
  const [editingDiscount, setEditingDiscount] = useState(null); // Track editing state
  const [warningMessage, setWarningMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false); // New state to track if editing or adding

  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const { user } = useContext(AuthContext);

  // Fetch the list of users (clients)
  const getClient = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${API_URL}/radius/subscriptions/discount/add/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
            client_id: props.client_id,
          },
        }
      );

      if (response.status === 200) {
        setUsers(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  // Fetch the list of current discounts for the client
  const getDiscounts = useCallback(async () => {
    try {
      const response = await Axios.get(
        `${API_URL}/radius/subscriptions/discounts/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: { username: user.username, client_id: props.client_id },
        }
      );

      if (response.status === 200) {
        setDiscounts(response.data);
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getClient();
    getDiscounts();

    // Refresh the discounts every 1 minute
    const intervalId = setInterval(() => {
      getDiscounts(); // Refresh the discounts
    }, 60000); // 60,000 milliseconds = 1 minute

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [getClient, getDiscounts]);

  const userOptions = users.map((item) => ({
    label: item.username,
    value: item.subscription_id,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    setWarningMessage("");

    const url = isEditing
      ? `${API_URL}/radius/subscriptions/discount/add/` // Same endpoint for edit
      : `${API_URL}/radius/subscriptions/discount/add/`; // Same endpoint for new submit

    const method = isEditing ? "PUT" : "POST"; // Use PUT for editing

    const data = {
      client_id: props.client_id,
      discountPercentage,
      discountAmount,
      discountDescription,
      subscription_id: username,
      userdealer: user.username,
    };

    if (isEditing) {
      data.discount_id = editingDiscount.discount_id; // Include the discount ID for editing
    }

    try {
      const response = await Axios({
        method,
        url,
        data,
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      });

      // If editing, make sure to update the discount's ID after successful edit
      if (isEditing) {
        setDiscounts((prevDiscounts) =>
          prevDiscounts.map((discount) =>
            discount.discount_id === editingDiscount.discount_id
              ? { ...discount, ...data, discount_id: response.data.discount_id }
              : discount
          )
        );
      } else {
        setDiscounts((prevDiscounts) => [
          ...prevDiscounts,
          { ...data, discount_id: response.data.discount_id },
        ]);
      }

      // Reset the form and switch button back to "Submit" after edit
      setDiscountAmount("");
      setDiscountPercentage("");
      setDiscountDescription("");
      setEditingDiscount(null); // Reset editing state after successful edit
      setIsEditing(false); // Set isEditing to false after submission
    } catch (error) {
      console.error(error);
    }
  };

  // Handle discount edit
  const handleEdit = (discount) => {
    setEditingDiscount(discount);
    setDiscountAmount(discount.amount);
    setDiscountPercentage(discount.percentage);
    setDiscountDescription(discount.description);
    setIsEditing(true); // Set isEditing to true when editing a discount
  };

  // Handle discount delete
  const handleDelete = async (discount_id) => {
    try {
      await Axios.delete(`${API_URL}/radius/subscriptions/discount/add/`, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
        params: {
          discount_id: discount_id,
          userdealer: user.username,
          client_id: props.client_id,
        },
      });

      // Immediately remove the deleted discount from state
      setDiscounts((prevDiscounts) =>
        prevDiscounts.filter((discount) => discount.discount_id !== discount_id)
      );
    } catch (error) {
      console.error(error);
    }
  };

  // Button to reset form and switch to adding mode
  const handleAddNew = () => {
    setIsEditing(false);
    setDiscountAmount("");
    setDiscountPercentage("");
    setDiscountDescription("");
    setUsername(""); // Reset username for new discount
    setEditingDiscount(null); // Reset editing state
  };

  return (
    <div className="discount-form-container">
      <form onSubmit={handleSubmit} className="discount-form">
        <div className="form-group">
          <label htmlFor="username">Username:</label>
          <Select
            id="username"
            className="select-input"
            placeholder="Choose Username"
            options={userOptions}
            onChange={(opt) => {
              setUsername(opt.value);
              setWarningMessage(""); // Clear warning when a username is selected
            }}
            value={userOptions.find((option) => option.value === username)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="discount-amount">Discount Amount:</label>
          <input
            id="discount-amount"
            className="form-control"
            type="number"
            value={discountAmount}
            placeholder="Discount Amount"
            onChange={(e) => setDiscountAmount(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="discount-percentage">Discount Percentage:</label>
          <input
            id="discount-percentage"
            className="form-control"
            type="number"
            value={discountPercentage}
            placeholder="Discount Percentage"
            onChange={(e) => setDiscountPercentage(e.target.value)}
          />
        </div>

        <div className="form-group">
          <label htmlFor="discount-description">Description:</label>
          <textarea
            id="discount-description"
            className="form-control"
            value={discountDescription}
            placeholder="Description"
            onChange={(e) => setDiscountDescription(e.target.value)}
          />
        </div>

        <div className="form-group">
          <button type="submit" className="btn-primary">
            {isEditing ? "Replace" : "Submit"}{" "}
            {/* Button text based on isEditing */}
          </button>
        </div>

        {/* Button to switch back to adding mode */}
        {isEditing && (
          <button type="button" className="btn-primary" onClick={handleAddNew}>
            Add New Discount
          </button>
        )}
      </form>

      {warningMessage && (
        <div className="warning-message">{warningMessage}</div>
      )}

      <div className="discount-list">
        <h3>Current Discounts</h3>
        {discounts.length > 0 ? (
          <table className="discount-table">
            <thead>
              <tr>
                <th>ID</th>
                <th>Amount</th>
                <th>Percentage</th>
                <th>Description</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {discounts.map((discount) => (
                <tr key={discount.discount_id}>
                  <td>{discount.discount_id}</td>
                  <td>{discount.amount}</td>
                  <td>{discount.percentage}</td>
                  <td>{discount.description}</td>
                  <td>{new Date(discount.created_at).toLocaleString()}</td>
                  <td>
                    <button
                      className="btn-primary"
                      onClick={() => handleEdit(discount)}
                    >
                      Edit
                    </button>
                    <button
                      className="btn-cancel"
                      onClick={() => handleDelete(discount.discount_id)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No discounts found.</p>
        )}
      </div>
    </div>
  );
}

export default Discount;
