import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";

function EditOrder({ invoice_id }) { // Receive invoice_id as a prop
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [cat, setcat] = useState([]);
  const [error, setError] = useState(null); // State to store error messages
  const [totalProductPrice, setTotalProductPrice] = useState(0); // State for total product price
  const [totalServicePrice, setTotalServicePrice] = useState(0); // State for total service price
  const [totalPrice, setTotalPrice] = useState(0); // State for total price

  //fetches the data(products and services) of the invoice of the dealer/reseller
  useEffect(() => {
    let isMounted = true; // Track mounted status
    const getorders = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/order/total/dealers/dashboard/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
            invoice_id: invoice_id, // Use the passed invoice_id
          },
        });
  
        // Check the status code and data format
        console.log("API Response Status:", response.status);
        console.log("API Response Data:", response.data);
  
        if (response.status === 200 && isMounted) {
          setcat(response.data); // Ensure response.data matches expected structure
          console.log("Data saved to state:", response.data); // Confirm the data is being saved

          // Calculate total prices for products and services
          let totalProduct = 0;
          let totalService = 0;

          response.data.forEach((item) => {
            if (item.item_type === "product") {
              totalProduct += item.selling_price * item.quantity;
            } else if (item.item_type === "service") {
              totalService += item.selling_price * item.quantity;
            }
          });

          setTotalProductPrice(totalProduct);
          setTotalServicePrice(totalService);
          setTotalPrice(totalProduct + totalService);
        }
      } catch (error) {
        console.error("Error fetching invoice details:", error);
        setError("Failed to fetch invoice details. Please try again later."); // Set error message
      }
    };
    
    getorders();
  
    return () => {
      isMounted = false; // Cleanup on unmount
    };
  }, [invoice_id]);
  

  return (
    <div className="testi">
      <div className="mod">
        <h3>Invoice Details for {invoice_id}</h3>
        {error && <div className="alert alert-danger">{error}</div>} {/* Display error message */}
        <table className="table">
          <thead>
            <tr>
              <th>Item Type</th>
              <th>Item Name</th>
              <th>Quantity</th>
              <th>Selling Price</th>
            </tr>
          </thead>
          <tbody>
            {cat.map((item, index) => (
              <tr key={index}>
                <td>{item.item_type}</td>
                <td>
                  {item.item_type === "product"
                    ? item.item_name // Ensure this matches your data structure
                    : item.item_type === "service"
                    ? item.item_name // Ensure this matches your data structure
                    : ""}
                </td>
                <td>{item.quantity}</td>
                <td>{item.selling_price}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="totals">
          <h4>Total Product Price: {totalProductPrice}</h4>
          <h4>Total Service Price: {totalServicePrice}</h4>
          <h4>Total Price: {totalPrice}</h4>
        </div>
      </div>
    </div>
  );
}

export default EditOrder;
