import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import AuthContext from "../context/AuthContext";
import { API_URL } from "../components/api";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert"; // Import Alert for pop-up messages
import Select from "react-select";

function EditWAP(props) {
  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [sites, setSites] = useState([]);
  const [name, setName] = useState("");
  const [ip, setIp] = useState("");
  const[mac,setMac]=useState("")
  const [frequency, setFrequency] = useState(0);
  const [ssid, setSsid] = useState("");
  const [siteID, setSiteID] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Destructure props
  const { ap_id: id, name: namel, ip: ipl, mac: macl, frequency: freq,ssid:ssidl,site_id:site_id,site_name:site_name } = props;

  useEffect(() => {
    let isMounted = true;

    const getsites = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/sites/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });

        if (response.status === 200 && isMounted) {
          setSites(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getsites();

    return () => {
      isMounted = false;
    };
  }, [authTokens.access, user.username]);

  const options = sites.map((item) => {
    return {
      label: item.site_name,
      value: item.site_id,
    };
  });
 


  const handleSubmit = (e) => {
    e.preventDefault();

    Axios.post(
      `${API_URL}/radius/wirelessAccessPoint/table/`,
      {
        Action: "Put",
        ap_id: id,
        name: name || namel,
        ip: ip || ipl,
        mac: mac || macl,
        frequency: frequency || freq,
        ssid: ssid || ssidl,
        site_id: siteID || site_id,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log("Wireless Access Point updated:", response);
        setShowSuccess(true); // Show success message
        setTimeout(() => setShowSuccess(false), 3000); // Auto-hide after 3 seconds
      })
      .catch((error) => {
        console.error("Error updating Wireless Access Point:", error);
        setErrorMessage(
          error.response?.data?.message || "An error occurred while updating the Wireless Access Point."
        );
        setShowError(true); // Show error message
        setTimeout(() => setShowError(false), 5000); // Auto-hide after 5 seconds
      });
  };

    // Validate IPv4 address format
const isValidIP = (value) => {
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;
  return ipRegex.test(value);
};

// Validate MAC address format
const isValidMAC = (value) => {
  const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
  return macRegex.test(value);
};

  return (
    <>
      {showSuccess && (
        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
          Wireless Access Point updated successfully!
        </Alert>
      )}
      {showError && (
        <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
          {errorMessage}
        </Alert>
      )}
      <Modal.Body>
      <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label>Wireless Access Point Name:</label>
        <input
          type="text"
          className="form-control"
          value={name}
          onChange={(e) => setName(e.target.value)} 
          placeholder={namel}
        />
      </div>
      <div className="form-group">
        <label>IP Address:</label>
        <input
          type="text"
          className={`form-control ${
            ip && ip !== ipl && !isValidIP(ip) ? "is-invalid" : ""
          }`}
          value={ip}
          onChange={(e) => setIp(e.target.value)}
          placeholder={ipl}
        />
        {ip && ip !== ipl && !isValidIP(ip) && (
          <div className="invalid-feedback">Please enter a valid IPv4 address.</div>
        )}
      </div>
      <div className="form-group">
        <label>Mac Address:</label>
        <input
          type="text"
          className={`form-control ${
            mac && mac !== macl && !isValidMAC(mac) ? "is-invalid" : ""
          }`}
          value={mac}
          onChange={(e) => setMac(e.target.value)}
          placeholder={macl}
        />
        {mac && mac !== macl && !isValidMAC(mac) && (
          <div className="invalid-feedback">
            Please enter a valid MAC address (XX:XX:XX:XX:XX:XX).
          </div>
        )}
      </div>
      <div className="form-group">
      <label>Frequency:</label>
      <input
        type="number"
        min={0}
        className="form-control"
        value={frequency || ""} // Use an empty string when frequency is falsy
        onChange={(e) => setFrequency(e.target.value)}
        placeholder={freq} // Placeholder will appear when value is empty
      />
    </div>
      <div className="form-group">
        <label>SSID:</label>
        <input
          type="text"
          className="form-control"
          value={ssid}
          onChange={(e) => setSsid(e.target.value)}
          placeholder={ssidl}
        />
      </div> 
        <div className="form-group">
              <label>
            Site Name:
            <Select
              className="search-line"
              options={options}
              onChange={(selectedOption) => setSiteID(selectedOption?.value || "")}
              placeholder={site_name}
            />
            </label>
          </div>
      {/* Submit button to add wireless access point */}
      <button
          type="submit"
          className="btn btn-success"
          disabled={
      (ip && ip !== ipl && !isValidIP(ip)) || (mac && mac !== macl && !isValidMAC(mac))
    }
        >
          Save Changes
        </button>
    </form>
      </Modal.Body>
    </>
  );
}

export default EditWAP;
