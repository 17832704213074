import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./Pages/context/AuthContext"; // Import the AuthProvider
import Dashboard from "./Pages/Dashboard/dashboard";
import Clients from "./Pages/Clients/clients";
import Plans from "./Pages/InternetPlans/Plans";
import ResellerPlans from "./Pages/ResellerPlans/Plans";
import PlansRes from "./Pages/Resellers/Plans";
import Dealers from "./Pages/Dealers/dealers";
import TestingDealers from "./Pages/Dealers/TestingDealers";

import DealerAccounting from "./Pages/DealerAccounting/dealerAccounting";

import Onboarding from "./Pages/Onboarding/Onboarding";

import DealerDetails from "./Pages/Dealers/dealerDetails";
import DealerDetailsFree from "./Pages/Dealers/dealerDetailsFree";
import SubResellerTable from "./Pages/Dealers/SubResellerstable";

import AdminConsole from "./Pages/AdminConsole/AdminConsole";
import Internetsub from "./Pages/InternetSubscriptions/internetsub";
import CommissionSub from "./Pages/CommissionSubscriptions/commissionSub";
import SubscriptionServices from "./Pages/SubscriptionServices/subscriptionServices";
import DashInternetsub from "./Pages/InternetSubscriptions/dashboardInternetSub";

import History from "./Pages/InternetSubscriptions/History";
import TransferDays from "./Pages/InternetSubscriptions/TransferDays";

import DealerSub from "./Pages/DealerSub/internetsub";
import DealerSubsss from "./Pages/DealerSubss/internetsub";
import Reseller from "./Pages/Reseller/Reseller";

import DeletedSub from "./Pages/DeletedSubs/internetsub";
import FreeSub from "./Pages/FreeSubscriptions/internetsub";
import Requests from "./Pages/Requests/Requests";

import Inventory from "./Pages/Inventory/Inventory";
import Products from "./Pages/Inventory/Products/Products";
import ProductCategories from "./Pages/Inventory/ProductCategories/ProductCategories";

import Mobile from "./Pages/Mobile/Mobile";

import FiberCores from "./Pages/FiberCores/FiberCores";
import Issues from "./Pages/Issues/Issues";
import WirelessTabs from "./Pages/Wireless/WirelessTabs";

import Labels from "./Pages/Labels/Labels";

import Suppliers from "./Pages/Inventory/Suppliers/Suppliers";
import OrderForm from "./Pages/Accounting/Order/OrderFormNew";
import Order from "./Pages/Accounting/Order/Orders";
import EditOrder from "./Pages/Accounting/Order/EditOrder";

import Quotation from "./Pages/Accounting/Quotation/Quotations";
import Invoice from "./Pages/Accounting/Invoice/Invoices";
import SystemInvoice from "./Pages/Accounting/SystemInvoice/Invoices";

import Receipt from "./Pages/Accounting/Receipt/Receipts";
import ShiftReceipts from "./Pages/Accounting/ShiftReceipts/ShiftReceipts";

import Drawer from "./Pages/Accounting/Drawer/Drawer";
import AccountingDashboard from "./Pages/Accounting/Dashboard/dashboard";

import Accounting from "./Pages/Accounting/Collection/accounting";
import Report from "./Pages/Accounting/Report/report";

import DealerOrderForm from "./Pages/Accounting/DealerInvoice/DealerOrderForm";
import DealerInvoiceTable from "./Pages/Accounting/DealerInvoice/DealerInvoiceTable";

import Warehouse from "./Pages/Inventory/Warehouses/Warehouse";

import Services from "./Pages/Inventory/Services/Services";

import ActionLog from "./Pages/ActionLog/ActionLog";

import Header from "./Pages/components/Header";
import LoginPage from "./Pages/Login/LoginPage";

function App() {
  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <Route
              render={() => (
                <div>
                  <Route component={Header} />
                  <Switch>
                    <PrivateRoute component={Dashboard} path="/" exact />
                    <PrivateRoute component={Clients} path="/clients" exact />
                    <PrivateRoute
                      component={Plans}
                      path="/plans/internet"
                      exact
                    />
                    <PrivateRoute
                      component={ResellerPlans}
                      path="/plans/resellers"
                      exact
                    />
                    <PrivateRoute
                      component={Reseller}
                      path="/resellers"
                      exact
                    />
                    <PrivateRoute
                      component={PlansRes}
                      path="/plans/reseller"
                      exact
                    />
                    <PrivateRoute
                      component={DealerSub}
                      path="/subscriptions/internet"
                      exact
                    />
                    <PrivateRoute
                      component={DeletedSub}
                      path="/subscriptions/internet/deleted"
                      exact
                    />
                    <PrivateRoute
                      component={FreeSub}
                      path="/subscriptions/internet/free"
                      exact
                    />
                    <PrivateRoute
                      component={DealerSubsss}
                      path="/subscriptions/internet/reseller"
                      exact
                    />
                    <PrivateRoute
                      component={Internetsub}
                      path="/subscriptions/internet/osss"
                      exact
                    />
                    <PrivateRoute
                      component={CommissionSub}
                      path="/subscriptions/internet/osss/commission"
                      exact
                    />
                    <PrivateRoute
                      component={SubscriptionServices}
                      path="/subscriptions/internet/osss/services"
                      exact
                    />
                    <PrivateRoute
                      component={DashInternetsub}
                      path="/subscriptions/internet/tabs/osss"
                      exact
                    />
                    <PrivateRoute
                      component={TransferDays}
                      path="/subscriptions/internet/transfer"
                      exact
                    />
                    <PrivateRoute
                      component={FiberCores}
                      path="/fiber/cores"
                      exact
                    />
                    <PrivateRoute
                      component={WirelessTabs}
                      path="/wireless"
                      exact
                    />
                    <PrivateRoute
                      component={Issues}
                      path="/issues/all/"
                      exact
                    />
                    <PrivateRoute component={History} path="/issues" exact />
                    <PrivateRoute
                      component={Dealers}
                      path="/internet/dealers"
                      exact
                    />
                    <PrivateRoute
                      component={TestingDealers}
                      path="/internet/dealers/testing"
                      exact
                    />
                    <PrivateRoute
                      component={DealerAccounting}
                      path="/internet/dealers/accounting"
                      exact
                    />
                    <PrivateRoute
                      component={SubResellerTable}
                      path="/internet/dealers/subresellers"
                      exact
                    />
                    <PrivateRoute
                      component={DealerDetails}
                      path="/dealer/details/"
                      exact
                    />
                    <PrivateRoute
                      component={DealerDetailsFree}
                      path="/dealer/details/free/"
                      exact
                    />
                    <PrivateRoute
                      component={AdminConsole}
                      path="/admin/console"
                      exact
                    />
                    <PrivateRoute
                      component={ActionLog}
                      path="/internet/actions"
                      exact
                    />
                    <PrivateRoute component={Labels} path="/labels" exact />

                    <PrivateRoute
                      component={Inventory}
                      path="/inventory"
                      exact
                    />
                    <PrivateRoute
                      component={Suppliers}
                      path="/inventory/suppliers"
                      exact
                    />
                    <PrivateRoute
                      component={Products}
                      path="/inventory/products"
                      exact
                    />
                    <PrivateRoute
                      component={Services}
                      path="/inventory/services"
                      exact
                    />
                    <PrivateRoute
                      component={Warehouse}
                      path="/inventory/warehouses"
                      exact
                    />
                    <PrivateRoute
                      component={ProductCategories}
                      path="/inventory/products/categories"
                      exact
                    />
                    <PrivateRoute
                      component={OrderForm}
                      path="/accounting/order/form"
                      exact
                    />
                    <PrivateRoute
                      component={Order}
                      path="/accounting/order"
                      exact
                    />
                    <PrivateRoute
                      component={EditOrder}
                      path="/accounting/order/edit"
                      exact
                    />
                    <PrivateRoute
                      component={DealerOrderForm}
                      path="/accounting/order/form/dealer"
                      exact
                    />
                    <PrivateRoute
                      component={DealerInvoiceTable}
                      path="/accounting/order/dealer"
                      exact
                    />
                    <PrivateRoute
                      component={Accounting}
                      path="/accounting/collection"
                      exact
                    />
                    <PrivateRoute
                      component={AccountingDashboard}
                      path="/accounting/dashboardossss"
                      exact
                    />
                    <PrivateRoute
                      component={Quotation}
                      path="/accounting/quotation"
                      exact
                    />
                    <PrivateRoute
                      component={Invoice}
                      path="/accounting/invoice"
                      exact
                    />
                    <PrivateRoute
                      component={SystemInvoice}
                      path="/accounting/invoice/system"
                      exact
                    />
                    <PrivateRoute
                      component={Drawer}
                      path="/accounting/drawersos"
                      exact
                    />
                    <PrivateRoute
                      component={Report}
                      path="/accounting/report/monthly"
                      exact
                    />
                    <PrivateRoute
                      component={Receipt}
                      path="/accounting/receipts"
                      exact
                    />
                    <PrivateRoute
                      component={ShiftReceipts}
                      path="/accounting/shift/receipts"
                      exact
                    />
                    <PrivateRoute
                      component={Onboarding}
                      path="/onboarding"
                      exact
                    />
                    <PrivateRoute component={Mobile} path="/mobile" exact />
                    <PrivateRoute component={Requests} path="/requests" exact />
                  </Switch>
                </div>
              )}
            />
          </Switch>
        </AuthProvider>
      </Router>
    </div>
  );
}

export default App;
