import React, { useState, useContext, useEffect } from "react";
import "./Receipt.css";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import logo from "./logo.png"; // Adjust the path based on your project structure
import "./receipts.css";

function NewReceiptPrint(props) {
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const { user } = useContext(AuthContext);
  let receipt_id = props.receipt_id;
  const [data, setData] = useState({});

  useEffect(() => {
    let isMounted = true;

    const getReceipt = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/inventory/receipt/detailsss/`,
          {
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
              "Content-Type": "application/json",
            },
            params: {
              username: user.username,
              receipt_id: receipt_id,
            },
          }
        );

        if (response.status === 200 && isMounted) {
          setData(response.data);
          console.log(response.data);
          console.log(receipt_id);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getReceipt();

    return () => {
      isMounted = false;
    };
  }, [authTokens.access, receipt_id, user.username]);

  useEffect(() => {
    console.log("Data state updated:", data);
  }, [data]);

  const getFullName = () => {
    return data.fullname_en || data.fullname_dealer;
  };

  const handlePrint = () => {
    const printWindow = window.open("", "_blank");

    // Create the HTML content for the print window
    printWindow.document.write(`<!DOCTYPE html>
      <html>
        <head>
          <title>Receipt</title>
          <style>
            body { font-family: Arial, sans-serif; }
            .receipt { width: 80%; margin: auto; }
            .print-button { display: none; }
            .line { display: flex; justify-content: space-between; }
            p { margin: 0px 0; padding: 0; }
            .left { float: left; }
            .right { float: right; }
          </style>
        </head>
        <body>
          <h5 style="display: flex; align-items: center;">
            <div>
              <p>Omega Speed</p>
              <p>76842442</p>
              <p>Date: ${new Date().toLocaleDateString()}</p>
            </div>
            <img id="logo" src="${logo}" alt="Logo" style="height: 80px; margin-left: 40px;" />
          </h5>
          <p>------------------------------------------</p>
          <h1 style="margin: 5px 0;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Receipt</h1>
          <p>------------------------------------------</p>
          <div>Full Name:&nbsp;&nbsp;${getFullName()}</div>
          <div class="line"><p class="left">Balance (USD):</p><p class="right">${
            data.balance_record
          }</p></div>
          <div class="line"><p class="left">Paid (USD):</p><p class="right">${
            data.paid_amount_usd
          }</p></div>
          <div class="line"><p class="left">Paid (LBP):</p><p class="right">${
            data.paid_amount_lbp
          }</p></div>
          <div class="line"><p class="left">Exchange Rate:</p><p class="right">${
            data.exchange_rate
          }</p></div>
          <div class="line"><p class="left">Remaining Amount:</p><p class="right">${
            data.balance_usd
          }</p></div>
          <p>------------------------------------------</p>
          <div class="line"><p class="left">Discount (USD):</p><p class="right">${
            data.discount_usd
          }</p></div>
          <div class="line"><p class="left">Discount (LBP):</p><p class="right">${
            data.discount_lbp
          }</p></div>
          <p>------------------------------------------</p>
          <p>Thank you for your payment.</p>
        </body>
      </html>
    `);

    printWindow.document.close();

    printWindow.document.getElementById("logo").onload = () => {
      printWindow.print();
      printWindow.onafterprint = () => {
        printWindow.close();
      };
    };
  };

  return (
    <div className="receipt">
      <h1
        style={{
          alignItems: "center",
        }}
      >
        Receipt
        <img
          src={logo}
          alt="Logo"
          style={{ height: "50px", marginLeft: "20px" }}
        />
      </h1>
      <p>Date: {new Date().toLocaleDateString()}</p>
      <p>Full Name: {getFullName()}</p>
      <p>Balance (USD): {data.balance_record}</p>
      <p>Paid Amount (USD): {data.paid_amount_usd}</p>
      <p>Paid Amount (LBP): {data.paid_amount_lbp}</p>
      <p>Exchange Rate: {data.exchange_rate}</p>
      <p>Remaining: {data.balance_usd}</p>

      <p>Discount (USD): {data.discount_usd}</p>
      <p>Discount (LBP): {data.discount_lbp}</p>
      <p>Thank you for your payment.</p>
      <button className="print-button" onClick={handlePrint}>
        Print Receipt
      </button>
    </div>
  );
}

export default NewReceiptPrint;
