import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";

function ReceiptEdit(props) {
  let [clients, setclients] = useState([]);

  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  let { user } = useContext(AuthContext);
  const receiptId = props.receiptId;
  const client_id = props.client_id;
  const paid_amount_usd = props.paid_amount_usd;
  const paid_amount_lbp = props.paid_amount_lbp;
  const exchange_rate = props.exchange_rate;
  const discount_lbp = props.discount_lbp;
  const returned_lbp = props.returned_lbp;
  const discount_usd = props.discount_usd;
  const fullname_en = props.fullname_en;
  function refreshPage() {
    window.location.reload(false);
  }
  useEffect(() => {
    let isMounted = true;

    const getclient = async () => {
      try {
        const response = await Axios.get(`${API_URL}/administration/clients/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: "admin",
          },
        });

        if (response.status === 200 && isMounted) {
          setclients(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getclient();

    return () => {
      isMounted = false;
    };
  }, []);

  // Rest of the component code

  const clientss = clients.map((item) => {
    return {
      label: item.fullname_en,
      value: item.client_id,
    };
  });

  const [paidUsd, setPaidUsd] = useState("");
  const [paidLbp, setPaidLbp] = useState("");
  const [returnedLbp, setReturnedLbp] = useState("");
  const [discountLbp, setDiscountLbp] = useState("");
  const [discountUsd, setDiscountUsd] = useState("");
  const [exchangeRate,setExchangeRate]=useState("")
  
  const [client, setClient] = useState("");

  let handleSubmit = async (e) => {
    e.preventDefault();
    Axios.post(
      `${API_URL}/inventory/receipts/edit/`,
      {
        client_id: client,
        receipt_id: receiptId,
        paidLbp: paidLbp,
        paidUsd: paidUsd,
        exchange_rate:exchangeRate,
        returnedLbp: returnedLbp,
        discountLbp: discountLbp,
        discountUsd: discountUsd,
        userdealer: user.username,
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        refreshPage();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="mod">
      <form onSubmit={handleSubmit}>
        <div className="name">
          <div className="form-group">
            <label>
              Client:
              <Select
                className="search-line"
                placeholder={fullname_en}
                options={clientss}
                onChange={(opt) => setClient(opt.value)}
              />
            </label>
            <label>
              Paid USD:
              <input
                className="form-control"
                type="number"
                value={paidUsd}
                placeholder={paid_amount_usd}
                onChange={(e) => setPaidUsd(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Paid LBP:
              <input
                className="form-control"
                type="number"
                value={paidLbp}
                placeholder={paid_amount_lbp}
                onChange={(e) => setPaidLbp(e.target.value)}
              />
            </label>
          </div>

          <div className="form-group">
            <label>
              Returned LBP:
              <input
                className="form-control"
                type="number"
                value={returnedLbp}
                placeholder={returned_lbp}
                onChange={(e) => setReturnedLbp(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Discount LBP:
              <input
                className="form-control"
                type="number"
                value={discountLbp}
                placeholder={discount_lbp}
                onChange={(e) => setDiscountLbp(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Discount USD:
              <input
                className="form-control"
                type="number"
                value={discountUsd}
                placeholder={discount_usd}
                onChange={(e) => setDiscountUsd(e.target.value)}
              />
            </label>
          </div>
          <div className="form-group">
            <label>
              Exchange:
              <input
                className="form-control"
                type="number"
                value={exchangeRate}
                placeholder={exchange_rate}
                onChange={(e) => setExchangeRate(e.target.value)}
              />
            </label>
          </div>
        </div>

        <div className="fo">
          <button type="submit" className="btn btn-primary btn-md">
            Edit
          </button>
        </div>
      </form>
    </div>
  );
}

export default ReceiptEdit;
