import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridFooterContainer,
  GridFooter,
  GridPagination,
} from "@mui/x-data-grid";
import Typography from "@mui/material/Typography";
import ExcelSVG from "../../ExcelSVG.svg";
import * as XLSX from "xlsx";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { API_URL } from "../components/api";
import AuthContext from "../context/AuthContext";
import "./ZoneDashboard.css"; // Add your custom styles here

function ZoneDashboard() {
  const { user } = useContext(AuthContext);
  const [zones, setZones] = useState([]); // State to store fiber zones
  const [selectedZone, setSelectedZone] = useState(""); // State to store the selected zone
  const [clientActiveCount, setClientActiveCount] = useState(0); // State for active client count
  const [clientInactiveCount, setClientInactiveCount] = useState(0); // State for inactive client count
  const [buildingActiveCount, setBuildingActiveCount] = useState(0); // State for active building count
  const [buildingReadyCount, setBuildingReadyCount] = useState(0); // State for ready building count
  const [subscriptionsOnlineCount, setSubscriptionsOnlineCount] = useState(0); // State for online subscription count
  const [subscriptionsOfflineCount, setSubscriptionsOfflineCount] = useState(0); // State for offline subscription count
  const [loading, setLoading] = useState(false); // State to manage loading state
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState(""); // To capture search input
  const [searchQuery, setSearchQuery] = useState("");
  const [searchFilterTimeout, setSearchFilterTimeout] = useState(null); // For delayed search filter

  const [filteredRowsData, setFilteredRowsData] = useState([]); // State to store filtered rows
  const [filters, setFilters] = useState({
    status: "",
    username: "",
    fullName: "",
    code: "",
  });
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [gridFilterModel, setGridFilterModel] = useState({
    items: [],
  });
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  // Fetch fiber zones on component mount
  useEffect(() => {
    const fetchZones = async () => {
      try {
        const authTokens = JSON.parse(localStorage.getItem("authTokens"));
        const response = await axios.get(`${API_URL}/radius/fiber/zone/`, {
          headers: { Authorization: `Bearer ${authTokens.access}` },
          params: { username: user.username },
        });
        setZones(response.data); // Set the fetched zones
      } catch (error) {
        console.error("Error fetching fiber zones:", error);
      }
    };

    fetchZones();
  }, [user.username]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${API_URL}/radius/fiber/zone/subscriptions/`;
  
        const response = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
            zone: selectedZone, // Always include zone, even if it's "All"
          },
        });
  
        if (response.status === 200) {
          setData(response.data);
          console.log(response.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [user.username, authTokens.access, selectedZone]);

  const getStatusCellStyle = (params) => {
    let statusColor = "";
    switch (params.value) {
      case "offline":
        statusColor = "status-offline";
        break;
      case "online":
        statusColor = "status-online";
        break;
      case "inactive":
        statusColor = "status-inactive";
        break;
      case "expired":
        statusColor = "status-expired";
        break;
      case "hold":
        statusColor = "status-hold";
        break;
      default:
        break;
    }
    return statusColor;
  };

  const columns = [
    {
      field: "status",
      headerName: "Status",
      width: 70,
      cellClassName: (params) => getStatusCellStyle(params), // Apply cell style based on status
    },
    { field: "username", headerName: "Username", minWidth: 100 },
    {
      field: "fullname_en",
      headerName: "Full Name",
      flex: 2,
      minWidth: 250,
    },
    { field: "code", headerName: "Code", minWidth: 100 },
  ]

  const getRowId = (row) => row.subscription_id;

  // Create a delayed search filter function
  const delaySearchFilter = (input) => {
    setSearchQuery(input);
  };

  useEffect(() => {
    // Save "searchValue" value before clearing other session storage items
    const searchValue = sessionStorage.getItem("searchImadReferral");

    // Clear all session storage items except "searchValue"
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchImadReferral") {
        sessionStorage.removeItem(key);
      }
    });

    // Restore "searchValue" value if needed
    if (searchValue) {
      sessionStorage.setItem("searchImadReferral", searchValue);
      setSearchQuery(searchValue);
      setSearchInput(searchValue); // Sync the searchInput with the restored value
    }
  }, []);

  // Handle search input change
  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchInput(query);
    sessionStorage.setItem("searchImadReferral", query);

    // Clear the previous timeout if it exists
    if (searchFilterTimeout) {
      clearTimeout(searchFilterTimeout);
    }

    // Set a new timeout to trigger filtering after a delay
    const newTimeout = setTimeout(() => {
      delaySearchFilter(query);
    }, 100); // Adjust the delay as needed
    setSearchFilterTimeout(newTimeout);
  };

  // Combined filtering logic
  const filteredRows = data.filter((row) => {
    // Apply search query filter
    const searchMatch = searchQuery.split(" ").every((word) =>
      Object.values(row).some((value) =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );

    // Apply modal filters
    const modalFilterMatch = Object.keys(filters).every((key) => {
      if (!filters[key]) return true; // Skip empty filters

      // Default filter handling for other fields
      const rowValue = String(row[key] || "").toLowerCase();
      const filterValue = String(filters[key]).toLowerCase();
      return rowValue.includes(filterValue);
    });

    // Apply DataGrid filters
    const gridFilterMatch = gridFilterModel.items.every((filterItem) => {
      if (!filterItem.value) return true;

      const cellValue = row[filterItem.field];
      const filterValue = filterItem.value;

      switch (filterItem.operator) {
        case "contains":
          return String(cellValue || "").toLowerCase().includes(String(filterValue).toLowerCase());
        case "equals":
          return String(cellValue || "") === String(filterValue);
        case "startsWith":
          return String(cellValue || "").toLowerCase().startsWith(String(filterValue).toLowerCase());
        case "endsWith":
          return String(cellValue || "").toLowerCase().endsWith(String(filterValue).toLowerCase());
        default:
          return true;
      }
    });

    // Return true only if all filters match
    return searchMatch && modalFilterMatch && gridFilterMatch;
  });

  const handleFilterChange = (field, value) => {
    // Update filters state
    setFilters((prevFilters) => {
      const newFilters = {
        ...prevFilters,
        [field]: value,
      };

      // Apply all active filters to get new filtered data
      const newFilteredData = data.filter((row) => {
        // Apply modal filters
        const modalFilterMatch = Object.keys(newFilters).every((key) => {
          if (!newFilters[key]) return true; // Skip empty filters
          const rowValue = String(row[key] || "").toLowerCase();
          const filterValue = String(newFilters[key]).toLowerCase();
          return rowValue.includes(filterValue);
        });

        // Apply search query filter
        const searchMatch = searchQuery.split(" ").every((word) =>
          Object.values(row).some((value) =>
            String(value).toLowerCase().includes(word.toLowerCase().trim())
          )
        );

        // Apply DataGrid filters
        const gridFilterMatch = gridFilterModel.items.every((filterItem) => {
          if (!filterItem.value) return true;

          const cellValue = row[filterItem.field];
          const filterValue = filterItem.value;

          switch (filterItem.operator) {
            case "contains":
              return String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase());
            case "equals":
              return String(cellValue) === String(filterValue);
            case "startsWith":
              return String(cellValue).toLowerCase().startsWith(String(filterValue).toLowerCase());
            case "endsWith":
              return String(cellValue).toLowerCase().endsWith(String(filterValue).toLowerCase());
            default:
              return true;
          }
        });

        return searchMatch && modalFilterMatch && gridFilterMatch;
      });

      // Update filteredRowsData with new filtered results
      setFilteredRowsData(newFilteredData);

      return newFilters;
    });
  };

  const columnHeaders = {
    status: "Status",
    username: "Username",
    fullname_en: "Full Name",
    code: "Code",
  };

  // Function to transform the rows based on columnHeaders
  const transformRowsForExport = (rows) => {
    return rows.map((row) => {
      const transformedRow = {};
      Object.keys(columnHeaders).forEach((key) => {
        transformedRow[columnHeaders[key]] = row[key];
      });
      return transformedRow;
    });
  };

  // Function to export to Excel
  const handleExportToExcel = () => {
    // Transform the rows to match the new column headers
    const transformedRows =
      filteredRowsData.length > 0
        ? transformRowsForExport(filteredRowsData)
        : transformRowsForExport(filteredRows);

    const worksheet = XLSX.utils.json_to_sheet(transformedRows);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "FiberZoneSubscriptions");
    XLSX.writeFile(workbook, "FiberZoneSubscriptions.xlsx");
  };

  const [isPressed, setIsPressed] = useState(false);

  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel();
    // Optional: Reset the button state after a short delay
    setTimeout(() => {
      setIsPressed(false);
    }, 200); // Adjust the timeout as needed
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick}
        variant="outline-success"
        style={{
          display: "flex",
          alignItems: "center",
          padding: "5px 10px",
          fontSize: "14px",
          border: "none",
          backgroundColor: isPressed ? "white" : "transparent", // Change background on press
          color: isPressed ? "black" : "#007bff", // Change text color on press
          cursor: "pointer",
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: "20px",
            marginRight: "8px",
            filter: isPressed ? "invert(0)" : "invert(1)", // Make the SVG white or retain its original color
          }}
        />
        Excel
      </Button>
      <Button onClick={() => setShowFilterModal(true)} variant="outline-primary">
        Filter
      </Button>
    </GridToolbarContainer>
  );

  useEffect(() => {
    if (selectedZone) {
      const fetchCounts = async () => {
        setLoading(true);
        try {
          const authTokens = JSON.parse(localStorage.getItem("authTokens"));
  
          // Fetch client active count
        const clientActiveResponse = await axios.get(
          `${API_URL}/administration/clients/count/`,
          {
            headers: { Authorization: `Bearer ${authTokens.access}` },
            params: {
              zone: selectedZone,
              status: 'active',
            },
          }
        );
        setClientActiveCount(clientActiveResponse.data.Client_Active);

        // Fetch client inactive count
        const clientInactiveResponse = await axios.get(
          `${API_URL}/administration/clients/count/`,
          {
            headers: { Authorization: `Bearer ${authTokens.access}` },
            params: {
              zone: selectedZone,
              status: 'inactive',
            },
          }
        );
        setClientInactiveCount(clientInactiveResponse.data.Client_Inactive);
  
          // Fetch building active count
        const buildingActiveResponse = await axios.get(
          `${API_URL}/administration/buildings/count/`,
          {
            headers: { Authorization: `Bearer ${authTokens.access}` },
            params: {
              zone: selectedZone,
              status: 'active',
            },
          }
        );
        setBuildingActiveCount(buildingActiveResponse.data.Building_Active);

        // Fetch building ready count
        const buildingReadyResponse = await axios.get(
          `${API_URL}/administration/buildings/count/`,
          {
            headers: { Authorization: `Bearer ${authTokens.access}` },
            params: {
              zone: selectedZone,
              status: 'ready',
            },
          }
        );
        setBuildingReadyCount(buildingReadyResponse.data.Building_Ready);
  
          // Fetch subscription online count
        const subscriptionOnlineResponse = await axios.get(
          `${API_URL}/radius/fiber/zone/count/`,
          {
            headers: { Authorization: `Bearer ${authTokens.access}` },
            params: {
              zone: selectedZone,
              status: 'online',
            },
          }
        );
        setSubscriptionsOnlineCount(subscriptionOnlineResponse.data.Subscription_Online);

        // Fetch subscription offline count
        const subscriptionOfflineResponse = await axios.get(
          `${API_URL}/radius/fiber/zone/count/`,
          {
            headers: { Authorization: `Bearer ${authTokens.access}` },
            params: {
              zone: selectedZone,
              status: 'offline',
            },
          }
        );
        setSubscriptionsOfflineCount(subscriptionOfflineResponse.data.Subscription_Offline);
  
        } catch (error) {
          console.error("Error fetching counts:", error);
        } finally {
          setLoading(false);
        }
      };
  
      fetchCounts();
    }
  }, [selectedZone]);

  return (
    <div>
    <div className="zone-dashboard">
    <div className="zone-dashboard-header" style={{display:"flex",flexDirection:"column", justifyContent:"center", alignItems:"center"}}>
      <h1 style={{color:"white"}}>Zone Dashboard</h1>

      {/* Zone Selector */}
      <div className="zone-selector">
        <label htmlFor="zone-select" style={{color:"white"}}>Select Zone:</label>
        <select
          id="zone-select"
          value={selectedZone}
          onChange={(e) => setSelectedZone(e.target.value)}
          disabled={loading}
        >
          <option value="">Select a zone</option>
          <option value="All">All</option>
          {zones.map((zone, index) => (
            <option key={index} value={zone.zone_name}>
              {zone.zone_name}
            </option>
          ))}
        </select>
      </div>
      </div>
      {/* Cards Displaying Counts */}
      <div className="cards-container">
        {/* Client Active Count Card */}
        <div className="stats-card">
          <h3>Active Clients</h3>
          <div className="card-value">{clientActiveCount}</div>
        </div>

        {/* Client Inactive Count Card */}
        <div className="stats-card">
          <h3>Inactive Clients</h3>
          <div className="card-value">{clientInactiveCount}</div>
        </div>

        {/* Building Active Count Card */}
        <div className="stats-card">
          <h3>Active Buildings</h3>
          <div className="card-value">{buildingActiveCount}</div>
        </div>

        {/* Building Ready Count Card */}
        <div className="stats-card">
          <h3>Ready Buildings</h3>
          <div className="card-value">{buildingReadyCount}</div>
        </div>

        {/* Subscription Online Count Card */}
        <div className="stats-card">
          <h3>Subscription Online</h3>
          <div className="card-value">{subscriptionsOnlineCount}</div>
        </div>

        {/* Subscription Offline Count Card */}
        <div className="stats-card">
          <h3>Subscription Offline</h3>
          <div className="card-value">{subscriptionsOfflineCount}</div>
        </div>

      </div>
    </div>
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearchChange}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            filterModel={gridFilterModel}
            onFilterModelChange={(newModel) => setGridFilterModel(newModel) || handleFilterChange}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {columns
            .filter((col) => col.field !== "actions")
            .map((col) => (
              <div key={col.field} className="filter">
                <label>{col.headerName}</label>
                <input
                  type="text"
                  placeholder={`Filter by ${col.headerName}`}
                  value={filters[col.field] || ""}
                  onChange={(e) => handleFilterChange(col.field, e.target.value)}
                  className="filter-input"
                />
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFilterModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
</div>
  );
}

export default ZoneDashboard;