import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import Axios from "axios";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import "./order.css";

function DealerOrderForm() {
  const { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));

  //use states for the modal opening and the selected products and services
  const [isAddProductModalOpen, setAddProductModalOpen] = useState(false);
  const [isAddServiceModalOpen, setAddServiceModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState([]);
  const [selectedService, setSelectedService] = useState([]);

  //use states for the quantity of the products and services
  const [productQuantity, setProductQuantity] = useState(1);
  const [serviceQuantity, setServiceQuantity] = useState(1);

  //use states for add products and services and dealers
  const [addedProducts, setAddedProducts] = useState([]);
  const [Dealers, setDealers] = useState([]);
  const [dealer, setDealer] = useState("");
  console.log(dealer)
  const [Products, setProducts] = useState([]);
  console.log(Products)
  const [Services, setServices] = useState([]);

  const [addedServices, setAddedServices] = useState([]);

  const [selectedDate, setSelectedDate] = useState(getDefaultDate()); // Use getDefaultDate here
  const [message, setMessage] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);

  //this is used to go to a specified page
  const history = useHistory();

  // Function to get the default date
  function getDefaultDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  //this function validates if the dealer and the(products or services) are selected 
  const validate = () => {
    return (
      dealer !== "" &&
      (addedServices.length !== 0 || addedProducts.length !== 0)
    );
  };

  useEffect(() => {
    let isMounted = true;

    //fetch the products 
    const getProducts = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/products/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });
    
        if (response.status === 200 && isMounted) {
          const productsWithPrices = response.data.map((product) => ({
            ...product,
            selling_price: product.selling_price, // Assuming this field is present in the response
          }));
          setProducts(productsWithPrices);
          console.log("Products fetched with selling prices:", productsWithPrices);
        }
      } catch (error) {
        console.error(error);
      }
    };
    

    //this function fetches the dealers/resellers
    const getDealers = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/order/total/dealers/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });
    
        if (response.status === 200) {
          setDealers(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    //fetches the services
    const getServices = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/services/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });
    
        if (response.status === 200 && isMounted) {
          const servicesWithPrices = response.data.map((service) => ({
            ...service,
            selling_price: service.selling_price, // Assuming this field is present in the response
          }));
          setServices(servicesWithPrices);
          console.log("Services fetched with selling prices:", servicesWithPrices);
        }
      } catch (error) {
        console.error(error);
      }
    };
    

    getProducts();
    getDealers();
    getServices();

    return () => {
      isMounted = false;
    };
  }, []);

  //maps the dealers, products, and services
  const DealerOptions = Dealers.map((item) => ({
    label: item.fullname_en,
    value: item.dealer_id,
  }));
  
  const productOptions = Products.map((item) => ({
    label: item.name,
    value: item.product_id,
    selling_price: item.selling_price, // Include selling price here
  }));
  
  
  const serviceOptions = Services.map((item) => ({
    label: item.name,
    value: item.service_id,
    selling_price: item.selling_price, // Include selling price here
  }));
  

  //handles the opening of the product and service modal
  const handleAddProductClick = () => {
    setAddProductModalOpen(true);
  };

  const handleAddServiceClick = () => {
    setAddServiceModalOpen(true);
  };

  //handles the closure of the product and service modal
  const handleAddProductModalClose = () => {
    setAddProductModalOpen(false);
    setSelectedProduct([]);
    setProductQuantity("1");
  };

  const handleAddServiceModalClose = () => {
    setAddServiceModalOpen(false);
    setSelectedService([]);
    setServiceQuantity("1");
  };

  //handles the deletion of the products and services
  const handleDeleteProduct = (index) => {
    const updatedProducts = [...addedProducts];
    updatedProducts.splice(index, 1);
    setAddedProducts(updatedProducts);
  };

  const handleDeleteService = (index) => {
    const updatedServices = [...addedServices];
    updatedServices.splice(index, 1);
    setAddedServices(updatedServices);
  };

  //handle the addition of product and services
  const handleAddProduct = () => {
    const newProduct = {
      id: selectedProduct.value,
      name: selectedProduct.label,
      quantity: productQuantity,
      selling_price: selectedProduct.selling_price,
    };
    setAddedProducts([...addedProducts, newProduct]);
    handleAddProductModalClose();
  };

  const handleAddService = () => {
    const newService = {
      id: selectedService.value,
      name: selectedService.label,
      quantity: serviceQuantity,
      selling_price: selectedService.selling_price, // Use the selected service's selling price
    };
    setAddedServices([...addedServices, newService]);
    handleAddServiceModalClose();
  };
  

  //Displays a success message and redirect to the accounting invoice page
  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });
    setTimeout(() => {
      setMessage(null);
     history.push("/accounting/order/dealer");
    }, 1000);
  };
// Calculate total amount whenever addedProducts or addedServices change
  useEffect(() => {
    const total = addedProducts.reduce(
      (acc, product) => acc + product.selling_price * product.quantity,
      0
    ) + addedServices.reduce(
      (acc, service) => acc + service.selling_price * service.quantity,
      0
    );
    setTotalAmount(total);
  }, [addedProducts, addedServices]);

  //handles the submission of the invoice of products and services for dealers/resellers
  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      Action: "Post",
      dealer_id: dealer,  // Send dealer_id instead of client
      products: addedProducts.map((product) => ({
        id: product.id,
        quantity: product.quantity,
        selling_price: product.selling_price || 0,
      })),
      services: addedServices.map((service) => ({
        id: service.id,
        quantity: service.quantity,
        selling_price:service.selling_price,
      })),
      date: selectedDate,
      userdealer: user.username,
      total_amount:totalAmount,
    };

    console.log("Payload being sent:", payload);

    try {
      const response = await Axios.post(`${API_URL}/inventory/order/dealer/`, payload, {
        headers: {
          Authorization: `Bearer ${authTokens.access}`,
          "Content-Type": "application/json",
        },
      });

      console.log(response);
      showMessage("Order successful!", "success");
    } catch (error) {
      console.error("Error occurred:", error.response ? error.response.data : error.message);
      showMessage("Order failed!", "danger");
    }
  };

  


  return (
    <div className="order">
      <div className="order-header">
        <div className="form-group">
        {/* this handles the selection of the dealers */}
          <label>
            Select Dealer:
            <Select
              className="search-line"
              placeholder="Choose Dealer"
              options={DealerOptions}
              onChange={(opt) => setDealer(opt.value)}
            />
          </label>
        </div>
        <div>
        {/* this handles the selection of the order date */}
          <label htmlFor="order-date">Order Date:</label>
          <input
            type="date"
            id="order-date"
            value={selectedDate}
            onChange={(e) => setSelectedDate(e.target.value)}
            style={{ color: "aliceblue" }}
          />
        </div>
      </div>

      <div className="order-body">
      {/*handles the table of the products with its addition and deletion */}
        <div className="table-container-products">
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Selling Price</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {addedProducts.map((product, index) => (
                  <TableRow key={index}>
                    <TableCell>{product.name}</TableCell>
                    <TableCell>{product.quantity}</TableCell>
                    <TableCell>{product.selling_price}</TableCell>
                    <TableCell>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteProduct(index)}
                      >
                        Delete
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button
            variant="contained"
            onClick={handleAddProductClick}
            className="buttonWithMargin"
          >
            Add Product
          </Button>
        </div>
        {/*Handles the table of the services with its addition and deletion */}
        <div className="table-container-services">
          <TableContainer component={Paper}>
          <Table>
          <TableHead>
            <TableRow>
              <TableCell>Service Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Selling Price</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {addedServices.map((service, index) => (
              <TableRow key={index}>
                <TableCell>{service.name}</TableCell>
                <TableCell>{service.quantity}</TableCell>
                <TableCell>{service.selling_price}</TableCell> {/* Show the selling price */}
                <TableCell>
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => handleDeleteService(index)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>        
          </TableContainer>
          <Button
            variant="contained"
            onClick={handleAddServiceClick}
            className="buttonWithMargin"
          >
            Add Service
          </Button>
        </div>
      </div>
      {/* the submit button wont work unless we select a dealer/reseller and (a product or services) */}
      <div className="order-footer">
      <div>Total Amount: ${totalAmount.toFixed(2)}</div>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={!validate()}
        >
          Submit Order
        </Button>
      </div>

      {/* this dialog handles the addition of a product with its quantities */}
      <Dialog
      open={isAddProductModalOpen}
      onClose={handleAddProductModalClose}
      PaperProps={{
        style: { width: '500px', height: '80vh' } // Set the width and max height
      }}
    >
      <DialogTitle>Add Product</DialogTitle>
      <DialogContent>
        <Select
          placeholder="Choose Product"
          options={productOptions}
          onChange={setSelectedProduct}
          isClearable // Allow clearing the selection
        />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <label style={{ marginRight: '10px' }}>Quantity:</label>
          <input
            type="number"
            value={productQuantity}
            onChange={(e) => setProductQuantity(e.target.value)}
            min="1"
            style={{
              width: '100px',
              appearance: 'auto',
              MozAppearance: 'textfield',
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddProductModalClose}>Cancel</Button>
        <Button
          onClick={handleAddProduct}
          disabled={!selectedProduct || 
                    !selectedProduct.label || // Check if product label is empty
                    !productQuantity || 
                    productQuantity <= 0} // Disable if quantity is invalid
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
    
    {/*this handles the addition of services with its quantities */}
    <Dialog
      open={isAddServiceModalOpen}
      onClose={handleAddServiceModalClose}
      PaperProps={{
        style: { width: '500px', height: '80vh' } // Set the width and max height
      }}
    >
      <DialogTitle>Add Service</DialogTitle>
      <DialogContent>
        <Select
          placeholder="Choose Service"
          options={serviceOptions}
          onChange={setSelectedService}
          isClearable // Allow clearing the selection
        />
        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
          <label style={{ marginRight: '10px' }}>Quantity:</label>
          <input
            type="number"
            value={serviceQuantity}
            onChange={(e) => setServiceQuantity(e.target.value)}
            min="1"
            style={{
              width: '100px',
              appearance: 'auto',
              MozAppearance: 'textfield',
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddServiceModalClose}>Cancel</Button>
        <Button
          onClick={handleAddService}
          disabled={!selectedService || 
                    !selectedService.label || // Check if service label is empty
                    !serviceQuantity || 
                    serviceQuantity <= 0} // Disable if quantity is invalid
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>    
      
      {message && (
        <div className={`message ${message.type}`}>
          {message.text}
        </div>
      )}
    </div>
  );
}

export default DealerOrderForm;
