import React, { useState, useContext ,useEffect} from "react";
import Axios from "axios"; // Import Axios for API requests
import Modal from "react-bootstrap/Modal"; // Import Bootstrap Modal
import Alert from "react-bootstrap/Alert"; // Import Alert for messages
import AuthContext from "../context/AuthContext"; // Import AuthContext for user authentication
import { API_URL } from "../components/api"; // Import API URL
import ONUTables from "./ONUTables"; // Import the ONUTables component

// Main ONU Component
function ONU() {
  // Modal state for showing/hiding the form
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false); // Close modal
  const handleShow = () => setShow(true); // Show modal

  return (
    <div className="Buildings">
      {/* Button to trigger the Add ONU modal */}
      <button
        onClick={handleShow}
        className="edit-client"
        style={{ marginTop: "10px", marginLeft: "10px" }}
      >
        Add ONU
      </button>

      {/* Main content displaying the ONUTables */}
      <div className="main">
        <ONUTables />
      </div>

      {/* Modal for adding a new ONU */}
      <Modal show={show} onHide={handleClose} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Add ONU</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ONUForm parentFunction={handleClose} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

// Form Component for Adding ONU
function ONUForm(props) {
  const { user } = useContext(AuthContext); // Get user details from AuthContext
  const authTokens = JSON.parse(localStorage.getItem("authTokens")); // Retrieve auth tokens from localStorage
  const [onu_name, setOnuName] = useState(""); // State for ONU name
  const [mac_address, setMacAddress] = useState(""); // State for MAC address
  const [pon, setPon] = useState(""); // State for PON
  const [fiberCores, setFiberCores] = useState([]); // State for Fiber Cores
  const [fiberCore_id, setFiberCoreId] = useState(""); // State for Fiber Core ID]
  const [olts, setOlts] = useState([]); // State for OLTS
  const [olt_id, setOltId] = useState(""); // State for OLT ID
  const [showSuccess, setShowSuccess] = useState(false); // State for success message
  const [showError, setShowError] = useState(false); // State for error message
  const [errorMessage, setErrorMessage] = useState(""); // State for error details

  useEffect(() => {
    let isMounted = true; // Prevent updates to unmounted components
  
    // Function to fetch OLTs
    const getOlts = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/fiber/cores/olt/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });
        if (isMounted && response.status === 200) {
          setOlts(response.data); // Set OLT options
        }
      } catch (error) {
        console.error("Error fetching OLTs:", error.response || error);
      }
    };
  
    // Function to fetch Fiber Cores
    const getFiberCores = async () => {
      try {
        const response = await Axios.get(`${API_URL}/radius/fiber/cores/select/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        });
        if (isMounted && response.status === 200) {
          setFiberCores(response.data); // Set Fiber Core options
        }
      } catch (error) {
        console.error("Error fetching Fiber Cores:", error.response || error);
      }
    };
  
    // Fetch OLTs and Fiber Cores
    getOlts();
    getFiberCores();
  
    // Cleanup function
    return () => {
      isMounted = false;
    };
  }, []);
  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    // API request to add a new ONU
    Axios.post(
      `${API_URL}/radius/fiber/cores/onu/`, // Endpoint
      {
        Action: "Post",
        onu_name: onu_name,
        mac_address: mac_address,
        pon: pon,
        core_id: fiberCore_id,
        olt_id: olt_id,
        userdealer: user.username, // Include the username from the AuthContext
      },
      {
        headers: {
          Authorization: `Bearer ${authTokens.access}`, // Pass auth token for authorization
        },
      }
    )
      .then((response) => {
        console.log("ONU added:", response); // Log success response
        setShowSuccess(true); // Show success message
        setTimeout(() => setShowSuccess(false), 3000); // Auto-hide success message after 3 seconds
        props.parentFunction(); // Close the modal
      })
      .catch((error) => {
        console.error("Error adding ONU:", error); // Log error
        setErrorMessage(
          error.response?.data?.message || "An error occurred while adding the ONU."
        ); // Set error message
        setShowError(true); // Show error message
        setTimeout(() => setShowError(false), 5000); // Auto-hide error message after 5 seconds
      });
  };

  const isValidMAC = (value) => {
    const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    return macRegex.test(value);
  };

  return (
    <>
      {/* Success message alert */}
      {showSuccess && (
        <Alert variant="success" onClose={() => setShowSuccess(false)} dismissible>
          ONU added successfully!
        </Alert>
      )}

      {/* Error message alert */}
      {showError && (
        <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
          {errorMessage}
        </Alert>
      )}

      {/* Form for adding ONU */}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>ONU NAME:</label>
          <input
            type="text"
            className="form-control"
            value={onu_name}
            onChange={(e) => setOnuName(e.target.value)} // Update state on input change
            placeholder="Enter ONU name"
            required
          />
        </div>
        <div className="form-group">
          <label>MAC Address:</label>
          <input
            type="text"
            className={`form-control ${!isValidMAC(mac_address) ? "is-invalid" : ""}`}
            value={mac_address}
            onChange={(e) => setMacAddress(e.target.value)}
            placeholder="Enter MAC Address"
            required
          />
          {!isValidMAC(mac_address) && (
            <div className="invalid-feedback">Please enter a valid MAC address.</div>
          )}
        </div>
        <div className="form-group">
          <label>PON:</label>
          <input
            type="text"
            className="form-control"
            value={pon}
            onChange={(e) => setPon(e.target.value)} // Update state on input change
            placeholder="Enter PON"
            required
          />
        </div>
        <div className="form-group">
          <label>OLT:</label>
          <select
            className="form-control"
            value={olt_id}
            onChange={(e) => setOltId(e.target.value)} // Update selected building ID on change
            required
          >
            <option value="" disabled>
              Select an OLT
            </option>
            {/* Map through buildings list to generate options */}
            {olts.map((olt) => (
              <option key={olt.olt_id} value={olt.olt_id}>
                {olt.olt_name}
              </option>
            ))}
          </select>
          </div>
          <div className="form-group">
          <label>Fiber Core:</label>
          <select
            className="form-control"
            value={fiberCore_id}
            onChange={(e) => setFiberCoreId(e.target.value)} // Update selected building ID on change
            required
          >
            <option value="" disabled>
              Select a Core
            </option>
            {/* Map through buildings list to generate options */}
            {fiberCores.map((fiberCore) => (
              <option key={fiberCore.core_id} value={fiberCore.core_id}>
                {fiberCore.code}
              </option>
            ))}
          </select>
          </div>
        <button type="submit" className="btn btn-success" style={{ marginTop: "10px" }} disabled={!isValidMAC(mac_address)}>
          Add ONU
        </button>
      </form>
    </>
  );
}
          

export default ONU; 
