import React, { useState, useEffect, useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { API_URL } from "../../components/api";
import EditOrder from "./InvoiceDetail";
import PrintInvoice from "./PrintInvoice";
import EditInvoice from "./EditInvoice";
import Button from "react-bootstrap/Button";
import EditingInvoices from "./EditingInvoice";
import TextField from "@mui/material/TextField";

import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
} from "@mui/x-data-grid";

import AuthContext from "../../context/AuthContext";

// For API Requests
import axios from "axios";
import ExcelSVG from './ExcelSVG.svg'
import * as XLSX from "xlsx";

function InvoiceTable() {
  const [data, setData] = useState([]);
  const [showdetails, setShowDetails] = useState(false);
  const [showedit, setShowEdit] = useState(false);
  const [showprint, setShowPrint] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [showEditInvoice, setShowEditInvoice] = useState(false);//New
  const [reason, setReason] = useState("");

  const [showStatusPrompt, setShowStatusPrompt] = useState(false);
  const [newStatus, setNewStatus] = useState("");
  const [invoice, setInvoice] = useState("");

  const [selectedRow, setSelectedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);

  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);

  const [filters,setFilters] = useState({
    status:"",
    fullname_en:"",
    date:"",
    total_amount:"",
    discount:"",
    totalMinusDiscount:"",
    initiator:"",
   })
   const [showFilterModal, setShowFilterModal] = useState(false);
  const [filteredRowsData, setFilteredRowsData] = useState([]); // State to store filtered rows
  const [gridFilterModel, setGridFilterModel] = useState({
    items: []
  });

  const { user } = useContext(AuthContext);
  const authTokens = JSON.parse(localStorage.getItem("authTokens"));

  const handleshowDetails = () => setShowDetails(true);
  const handlecloseDetails = () => setShowDetails(false);
  const handleshowPrint = () => setShowPrint(true);
  const handleclosePrint = () => setShowPrint(false);
  const handleshowEdit = () => setShowEdit(true);
  const handlecloseEdit = () => setShowEdit(false);

  const handleShowEditInvoice = (invoiceId) => {
    setSelectedInvoiceId(invoiceId);
    setShowEditInvoice(true);
  };

  const handleCloseEditInvoice = () => {
    setShowEditInvoice(false);
    setSelectedInvoiceId(null);
  };

  const [dealerDetails, setDealerDetails] = useState({
    order_id: 0,
    invoice_id: 0,
  });

  const updateDealerDetails = (details) => {
    setDealerDetails(details);
  };
  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/inventory/invoices/system/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data.reverse());
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, [user.username, authTokens.access]);

  const columns = [
    {
      field: "status",
      headerName: "Status",
      flex: 2,
      minWidth: 120,
      renderCell: (params) => (
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleStatusCellClick(params)}
        >
          {params.value}
        </div>
      ),
    },
    { field: "fullname_en", headerName: "Name", flex: 2, minWidth: 150 },
    { field: "date", headerName: "Date", flex: 2, minWidth: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value);
        return date.toLocaleString("en-GB", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        });
      },
     },
    { field: "total_amount", headerName: "Total", flex: 2, minWidth: 100 },
    { field: "discount", headerName: "Discount", flex: 2, minWidth: 100 },
    {
      field: "totalMinusDiscount",
      headerName: "Total - Discount",
      flex: 2,
      minWidth: 100,
      valueGetter: (params) => {
        const total = params.row.total_amount || 0;
        const discount = params.row.discount || 0;
        return total - discount;
      },
    },
    { field: "initiator", headerName: "Initiator", flex: 2, minWidth: 100 },
    {
      field: "actions",
      headerName: "Actions",
      minWidth: 230,
      flex: 2,
      renderCell: (params) => (
        <div>
          <Button
            variant="danger"
            onClick={() => handleOpenModal(params.row.invoice_id)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => handleShowEditInvoice(params.row.invoice_id)}
          >
            Edit Discount
          </Button>
          
        </div>
      ),
    },
  ];

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Clear the message after 5 seconds
    setTimeout(() => {
      setMessage(null);
    }, 5000);
  };

  const getRowId = (row) => row.invoice_id;

  useEffect(() => {
    // Save "searchCollector" value before clearing other session storage items
    const searchValue = sessionStorage.getItem("searchSystemInvoice");
  
    // Clear all session storage items except "searchCollector"
    Object.keys(sessionStorage).forEach((key) => {
      if (key !== "searchSystemInvoice") {
        sessionStorage.removeItem(key);
      }
    });
  
    // Restore "searchCollector" value if needed
    if (searchValue) {
      sessionStorage.setItem("searchSystemInvoice", searchValue);
      setSearchQuery(searchValue)
    }
  }, []);
  
  const handleSearch = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    sessionStorage.setItem("searchSystemInvoice", query);
  };

  const handleStatusCellClick = (params) => {
    if (params.row.status === "Pending") {
      setNewStatus("Done");
      setSelectedRow(params.row);
      setInvoice(params.row.invoice_id); // Set the invoice ID when the status cell is clicked
      setShowStatusPrompt(true);
    } else {
      setNewStatus(params.row.status || "Done"); // Set default value if status is not present
      setShowStatusPrompt(true);
      setSelectedRow(params.row);
      setInvoice(params.row.invoice_id); // Set the invoice ID when the status cell is clicked
    }
  };

  const handleStatusPromptClose = () => {
    setNewStatus("");
    setShowStatusPrompt(false);
    setSelectedRow(null);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/inventory/invoices/status/`,
        {
          status: newStatus,
          invoice: invoice,
          userdealer: user.username,
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      console.log(response);
      showMessage("Status changed!", "success");
      // Fetch updated data
      const updatedData = await axios.get(
        `${API_URL}/inventory/invoices/system/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        }
      );
      setData(updatedData.data.reverse());
    } catch (error) {
      console.log(error);
      showMessage("Edit failed!", "danger");
    }
  };
  const handleOpenModal = (receiptId) => {
    setSelectedInvoiceId(receiptId);
    setShowCancel(true);
  };

  const handleCloseModal = () => {
    setShowCancel(false);
    setSelectedInvoiceId(null);
  };

  const handleConfirm = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/inventory/invoices/cancel/`,
        { invoice_id: selectedInvoiceId, username: user.username,reason:reason },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        // Optionally, refresh the data or update the state to reflect the changes
        console.log("Receipt confirmed:", response.data);
      }
    } catch (error) {
      console.error("Error confirming receipt:", error);
    } finally {
      handleCloseModal();
      fetchData();
    }
  };
  const handleStatusConfirmation = () => {
    console.log("Invoice ID:", selectedRow.invoice_id);
    console.log("New Status:", newStatus);
    // Perform any additional actions here (e.g., API call, state update)
    handleSubmit();
    handleStatusPromptClose();
  };

  const handleFilterChange = (field, value) => {
    // Update filters state
    setFilters(prevFilters => {
      const newFilters = {
        ...prevFilters,
        [field]: value
      };
  
      // Apply all active filters to get new filtered data
      const newFilteredData = data.filter(row => {
        // Apply modal filters
        const modalFilterMatch = Object.keys(newFilters).every(key => {
          if (!newFilters[key]) return true; // Skip empty filters
          const rowValue = String(row[key] || '').toLowerCase();
          const filterValue = String(newFilters[key]).toLowerCase();
          return rowValue.includes(filterValue);
        });
  
        // Apply search query filter
        const searchMatch = searchQuery.split(" ").every(word =>
          Object.values(row).some(value =>
            String(value).toLowerCase().includes(word.toLowerCase().trim())
          )
        );
  
        // Apply DataGrid filters
        const gridFilterMatch = gridFilterModel.items.every(filterItem => {
          if (!filterItem.value) return true;
          
          const cellValue = row[filterItem.field];
          const filterValue = filterItem.value;
  
          switch (filterItem.operator) {
            case 'contains':
              return String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase());
            case 'equals':
              return String(cellValue) === String(filterValue);
            case 'startsWith':
              return String(cellValue).toLowerCase().startsWith(String(filterValue).toLowerCase());
            case 'endsWith':
              return String(cellValue).toLowerCase().endsWith(String(filterValue).toLowerCase());
            default:
              return true;
          }
        });
  
        return searchMatch && modalFilterMatch && gridFilterMatch;
      });
  
      // Update filteredRowsData with new filtered results
      setFilteredRowsData(newFilteredData);
      
      return newFilters;
    });
  };

  // Combined filtering logic
  const filteredRows = data.filter(row => {
    // Apply search query filter
    const searchMatch = searchQuery.split(" ").every(word =>
      Object.values(row).some(value =>
        String(value).toLowerCase().includes(word.toLowerCase().trim())
      )
    );

    // Apply modal filters
    const modalFilterMatch = Object.keys(filters).every(key => {
      if (!filters[key]) return true; // Skip empty filters
      const rowValue = String(row[key] || '').toLowerCase();
      const filterValue = String(filters[key]).toLowerCase();
      return rowValue.includes(filterValue);
    });

    // Apply DataGrid filters
    const gridFilterMatch = gridFilterModel.items.every(filterItem => {
      if (!filterItem.value) return true;
      
      const cellValue = row[filterItem.field];
      const filterValue = filterItem.value;

      switch (filterItem.operator) {
        case 'contains':
          return String(cellValue).toLowerCase().includes(String(filterValue).toLowerCase());
        case 'equals':
          return String(cellValue) === String(filterValue);
        case 'startsWith':
          return String(cellValue).toLowerCase().startsWith(String(filterValue).toLowerCase());
        case 'endsWith':
          return String(cellValue).toLowerCase().endsWith(String(filterValue).toLowerCase());
        default:
          return true;
      }
    });

    // Return true only if all filters match
    return searchMatch && modalFilterMatch && gridFilterMatch;
  });

  const columnHeaders = {
    status:"Status",
    fullname_en: "Full Name",
    date: "Date",
    total_amount: "Total",
    discount: "Discount",
    totalMinusDiscount:"Total - Discount",

  };
  // Function to transform the rows based on columnHeaders
const transformRowsForExport = (rows) => {
  return rows.map((row) => {
    const transformedRow = {};
    Object.keys(columnHeaders).forEach((key) => {
      transformedRow[columnHeaders[key]] = row[key];
    });
    return transformedRow;
  });
};

const handleExportToExcel = () => {
  // Transform the rows to match the new column headers
  const transformedRows = filteredRowsData.length > 0 
      ? transformRowsForExport(filteredRowsData) 
      : transformRowsForExport(filteredRows);

  const worksheet = XLSX.utils.json_to_sheet(transformedRows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "System Invoice");
  XLSX.writeFile(workbook, "System Invoice.xlsx");
};

const [isPressed, setIsPressed] = useState(false);

  const handleExportClick = () => {
    setIsPressed(true);
    handleExportToExcel();
    // Optional: Reset the button state after a short delay
    setTimeout(() => {
      setIsPressed(false);
    }, 200); // Adjust the timeout as needed
  };


  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
      <Button
        onClick={handleExportClick}
        variant="outline-success"
        style={{
          display: 'flex',
          alignItems: 'center',
          padding: '5px 10px',
          fontSize: '14px',
          border: 'none',
          backgroundColor: isPressed ? 'white' : 'transparent', // Change background on press
          color: isPressed ? 'black' : '#007bff', // Change text color on press
          cursor: 'pointer',
        }}
      >
        <img
          src={ExcelSVG}
          alt="Excel Icon"
          style={{
            width: '20px',
            marginRight: '8px',
            filter: isPressed ? 'invert(0)' : 'invert(1)', // Make the SVG white or retain its original color
          }}
        />
        Excel
      </Button>
      <Button
        onClick={() => setShowFilterModal(true)}
        variant="outline-primary"
      >Filter</Button>
    </GridToolbarContainer>
  );

  return (
    <div className="ClientTable">
      <input
        type="text"
        placeholder="Search..."
        value={searchQuery}
        onChange={handleSearch}
        className="search-client"
      />
      {!loading && data.length > 0 && (
        <div style={{ height: 520, width: "100%" }}>
          <DataGrid
            rows={filteredRows}
            columns={columns}
            getRowId={getRowId}
            filterModel={gridFilterModel}
            onFilterModelChange={(newModel) => setGridFilterModel(newModel) || handleFilterChange}
            components={{
              Toolbar: CustomToolbar,
            }}
            rowsPerPageOptions={[10, 100, 1000]}
            pagination
            disableSelectionOnClick
          />
        </div>
      )}
      <Modal
        show={showdetails}
        onHide={handlecloseDetails}
        className="modal-lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Details</Modal.Title>
        </Modal.Header>
        <EditOrder
          invoice_id={dealerDetails.invoice_id}
          order_id={dealerDetails.order_id}
        />
      </Modal>
      <Modal show={showedit} onHide={handlecloseEdit} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit</Modal.Title>
        </Modal.Header>
        <EditInvoice
          invoice_id={dealerDetails.invoice_id}
          order_id={dealerDetails.order_id}
        />
      </Modal>
      <Modal show={showprint} onHide={handleclosePrint} className="modal-lg">
        <Modal.Header closeButton>
          <Modal.Title>Print</Modal.Title>
        </Modal.Header>
        <PrintInvoice
          invoice_id={dealerDetails.invoice_id}
          order_id={dealerDetails.order_id}
          data={data.filter(
            (item) => item.invoice_id === dealerDetails.invoice_id
          )}
          data1={data}
        />
      </Modal>

      {/*New Modal */}
      <Modal show={showEditInvoice} onHide={handleCloseEditInvoice} className="modal-lg">
  <Modal.Header closeButton>
    <Modal.Title>Edit Discount</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    {selectedInvoiceId && (
      <EditingInvoices invoiceId={selectedInvoiceId} />
    )}
  </Modal.Body>
</Modal>

      <Modal show={showStatusPrompt} onHide={handleStatusPromptClose}>
        <Modal.Header closeButton>
          <Modal.Title>Change Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label htmlFor="newStatus">Select new status:</label>
          <select
            id="newStatus"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
            className="form-select"
          >
            <option value="Done">Done</option>
            <option value="Blocked">Blocked</option>
          </select>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={handleStatusPromptClose}
          >
            Cancel
          </button>
          <button
            className="btn btn-primary"
            onClick={handleStatusConfirmation}
          >
            Confirm
          </button>
        </Modal.Footer>
      </Modal>
      <Modal show={showCancel} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Cancel Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to cancel this invoice? Please provide a reason.
        <TextField
              autoFocus
              margin="dense"
              fullWidth
              variant="standard"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            /></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Filters</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {columns
            .filter((col) => col.field !== "actions")
            .map((col) => (
              <div key={col.field} className="filter">
                <label>{col.headerName}</label>
                <input
                  type="text"
                  placeholder={`Filter by ${col.headerName}`}
                  value={filters[col.field] || ""}
                  onChange={(e) => handleFilterChange(col.field, e.target.value)}
                  className="filter-input"
                />
              </div>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowFilterModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            margin: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default InvoiceTable;
