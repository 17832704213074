import React, { useState, useEffect, useContext, useRef } from "react";
import Axios from "axios";
import AuthContext from "../../context/AuthContext";
import { API_URL } from "../../components/api";
import "bootstrap/dist/css/bootstrap.css";
import "./printInvoice.css";
import logo from "./logo.png";

// Helper function to convert numbers to words
const convertToWords = (num) => {
  const a = [
    "", "one", "two", "three", "four", "five", "six", "seven", "eight", "nine",
    "ten", "eleven", "twelve", "thirteen", "fourteen", "fifteen", "sixteen", "seventeen", "eighteen", "nineteen",
  ];
  const b = ["", "", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
  const numToWords = (n) => {
    if (n < 20) return a[n];
    if (n < 100) return b[Math.floor(n / 10)] + (n % 10 !== 0 ? "-" + a[n % 10] : "");
    if (n < 1000)
      return a[Math.floor(n / 100)] + " hundred" + (n % 100 !== 0 ? " and " + numToWords(n % 100) : "");
    if (n < 1000000)
      return numToWords(Math.floor(n / 1000)) + " thousand" + (n % 1000 !== 0 ? " " + numToWords(n % 1000) : "");
    return "Number too large";
  };
  return numToWords(num);
};

function PrintInvoice(props) {
  const [cat, setCat] = useState([]);
  const [currentDate, setCurrentDate] = useState(
    new Date().toLocaleDateString("en-GB")
  );
  const printRef = useRef();
  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const invoiceData = props.data;

  useEffect(() => {
    let isMounted = true;
    const getOrders = async () => {
      try {
        const response = await Axios.get(`${API_URL}/inventory/invoice/details/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
            invoice_id: props.invoice_id,
          },
        });

        if (response.status === 200 && isMounted) {
          setCat(response.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    getOrders();

    return () => {
      isMounted = false;
    };
  }, [authTokens.access, props.invoice_id, user.username]);

  const handlePrint = () => {
    const printContent = printRef.current;
    const printWindow = window.open("", "", "height=500, width=800");

    const styles = `
      body {
        font-family: Arial, sans-serif;
      }
      .invoice-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 20px;
      }
      .invoice-header img {
        height: 80px;
      }
      .invoice-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
      }
      .invoice-table th, .invoice-table td {
        border: 1px solid #ddd;
        padding: 8px;
        text-align: left;
      }
      .invoice-footer {
        margin-top: 20px;
        text-align: right;
      }
    `;

    printWindow.document.write(`
      <html>
        <head>
          <title>Invoice</title>
          <style>${styles}</style>
        </head>
        <body>
          <div>${printContent.innerHTML}</div>
        </body>
      </html>
    `);

    printWindow.onload = function () {
      printWindow.print();
      printWindow.close();
    };
  };

  return (
    <div className="print-invoice-container">
      <div ref={printRef} className="invoice-content">
        <div className="invoice-header">
          <div>
            <img src={logo} alt="Logo" />
            <p>Ain w Zain Cross Road, Main Street</p>
            <p>Bakaata, El Chouf, Lebanon</p>
            <p>Phone: 00961-76842442</p>
            <div style={{ display: "flex",flexDirection:"column", justifyContent: "left" }}>
            <p style={{background:"#2E75B6",color:"white"}}><strong>Bill To:</strong></p>
            <p>Name: {invoiceData[0]?.fullname_en}</p>
            <p>Location: {cat[0]?.location}</p>
            <p>Telephone: {cat[0]?.telephone}</p>
            </div>
          </div>
          <div>
            <h1 style={{color:"#2E75B6",fontWeight:"bold"}}>Invoice</h1>
            <p><strong>Invoice #: </strong>{invoiceData[0]?.invoice_id}</p>
            <p><strong>Date:</strong> {invoiceData[0]?.date}</p>
            <p><strong>Creation Date:</strong> {currentDate}</p>
            
          </div>
        </div>

        <table className="invoice-table">
          <thead>
            <tr>
              <th style={{background:"#2E75B6",color:"white",fontWeight:"bold"}}>Description</th>
              <th style={{background:"#2E75B6",color:"white",fontWeight:"bold"}}>Unit</th>
              <th style={{background:"#2E75B6",color:"white",fontWeight:"bold"}}>Rate</th>
              <th style={{background:"#2E75B6",color:"white",fontWeight:"bold"}}>Quantity</th>
              <th style={{background:"#2E75B6",color:"white",fontWeight:"bold"}}>Price (USD)</th>
            </tr>
          </thead>
          <tbody>
            {cat.map((item, index) => (
              <tr key={index}>
                <td>{item.product_name || item.service_name}</td>
                <td>{item.unit || ""}</td>
                <td>{item.rate || ""}</td>
                <td>{item.quantity}</td>
                <td>${item.product_selling || item.service_selling}</td>
              </tr>
            ))}
            <tr>
            <td colSpan="3" style={{ textAlign: "left",border:"None",color:"#2E75B6",background:"#C6D9F1" }}><strong><i>Thank you for your business!</i></strong></td>
              <td style={{ textAlign: "right" ,border:"None",background:"#C6D9F1"}}>SUB-TOTAL</td>
              <td style={{background:"#DEEBF7"}}>${invoiceData[0]?.total_amount}</td>
            </tr>
            <tr>
              <td colSpan="4" style={{ textAlign: "right" ,background:"#C6D9F1"}}>DISCOUNT</td>
              <td style={{background:"#DEEBF7"}}>${invoiceData[0]?.discount}</td>
            </tr>
            <tr>
              <td colSpan="4" style={{ textAlign: "right" ,color:"#2E75B6",background:"#C6D9F1",fontWeight:"bold"}}>TOTAL</td>
              <td style={{background:"#DEEBF7",fontWeight:"bold"}}>${invoiceData[0]?.total_amount - invoiceData[0]?.discount}</td>
            </tr>
            
          </tbody>
        </table>
              <p style={{ textAlign: "right" }}>
                {convertToWords(invoiceData[0]?.total_amount - invoiceData[0]?.discount)} USD
              </p>

      </div>
      <button onClick={handlePrint} className="print-button">
        Print Invoice
      </button>
    </div>
  );
}

export default PrintInvoice;
