import React, { useState, useEffect, useContext } from "react";
import { API_URL } from "../components/api";
import "bootstrap/dist/css/bootstrap.css";
import Axios from "axios";
import Select from "react-select";
import AuthContext from "../context/AuthContext";

function OnboardingAdd(props) {
  const [message, setMessage] = useState(null);

  let { user } = useContext(AuthContext);
  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const [Note, setNotes] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [client, setClient] = useState(null);
  const [warning, setWarning] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [phone, setPhone] = useState("");
  const [Previous, setPrevious] = useState("");
  const [Location, setLocation] = useState("");
  const [Recipient, setRecipient] = useState("");
  const [Referral, setReferral] = useState("");
  const [username, setUsername] = useState(null);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [whatsappProof, setWhatsappProof] = useState(false);
  const [appInstalled, setAppInstalled] = useState(false);
  const [clients, setClients] = useState([]);
  const [usernames, setUsernames] = useState([]);
  const [clientOptions1, setClientOptions1] = useState([]);
  const [expirationDay, setExpirationDay] = useState(null); // New state for expiration day

  const [vipCount, setVipCount] = useState([]);
  const [standardCount, setStandardCount] = useState([]);
  const [collectorCount, setCollectorCount] = useState([]);
  const [imadCount, setImadCount] = useState([]);
  const [nmcCount, setNmcCount] = useState([]);
  const [specialCount, setSpecialCount] = useState([]);
  const validate = () => {
    return client !== null && username !== null;
  };

  // Fetch the client counts dynamically based on categories
  useEffect(() => {
    const fetchClientCounts = async () => {
      try {
        const response = await Axios.get(`${API_URL}/accounting/info/Counts/`, {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
          params: {
            username: user.username,
          },
        });

        // Extract counts from the response
        const counts = response.data;

        // Set the counts in state
        setVipCount(counts.VIP_Clients || 0);
        setStandardCount(counts.Standard_Clients || 0);
        setImadCount(counts.Imad_Clients || 0);
        setCollectorCount(counts.Collectors || 0);
        setNmcCount(counts.NMC_Clients || 0);
        setSpecialCount(counts.Special_Clients || 0);
      } catch (error) {
        console.error(error);
        showMessage("Failed to fetch client counts!", "danger");
      }
    };

    fetchClientCounts();
  }, []);
  let isMounted = true;
  const getClients = async () => {
    try {
      const response = await Axios.get(
        `${API_URL}/radius/subscriptions/clients/onboarding/`,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
            "Content-Type": "application/json",
          },
          params: {
            username: user.username,
          },
        }
      );

      if (response.status === 200 && isMounted) {
        setClients(response.data);
        const options = [
          ...new Map(
            response.data.map((item) => [
              item.client_id,
              { label: item.fullname, value: item.client_id },
            ])
          ).values(),
        ];
        setClientOptions1(options);
        console.log(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getClients();
    return () => {
      isMounted = false;
    };
  }, []);

  const customFilter = (option, inputValue) => {
    const words = inputValue.toLowerCase().split(" ");
    return words.every((word) => option.label.toLowerCase().includes(word));
  };

  const handleClientChange = (selectedOption) => {
    setClient(selectedOption);
    const filteredUsernames = clients
      .filter((item) => item.client_id === selectedOption.value)
      .map((item) => ({
        label: item.username,
        value: item.subscription_id,
      }));
    setUsernames(filteredUsernames);
    setUsername(null); // Reset the username selection when client changes
  };

  const clientOptions = [
    ...new Map(
      clients.map((item) => [
        item.client_id,
        { label: item.fullname, value: item.client_id },
      ])
    ).values(),
  ];

  const paymentOptions = [
    { value: "Office", label: "Office" },
    { value: "OMT", label: "OMT" },
    { value: "WISH", label: "WISH" },
    { value: "Collector", label: "Collector" },
  ];

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });

    // Close the modal after 1.2 seconds
    setTimeout(() => {}, 1200);

    // Clear the message after 1 second
    setTimeout(() => {
      setMessage(null);
    }, 1000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await Axios.post(
        `${API_URL}/radius/subscriptions/clients/onboarding/`,
        {
          Action: "Post",
          client_id: client.value,
          subscription_id: subscriptionId,
          Note: Note,
          payment: paymentMethod,
          referral: Referral,
          phone: phone,
          Previous: Previous,
          location: Location,
          recipient: Recipient,
          userdealer: user.username,
          whatsapp_proof: whatsappProof,
          app_installed: appInstalled,
          expiration_day: expirationDay, // Add expiration day here
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      if (response.status === 200) {
        console.log(response);
        showMessage("Creation successful!", "success");
        await getClients();
        setClient(null);
        setNotes("");
        setPaymentMethod("");
        setPhone("");
        setPrevious("");
        setUsername(null);
        setSubscriptionId("");
        setWhatsappProof(false);
        setAppInstalled(false);
        setExpirationDay(null); // Reset expiration day
        setUsernames([]);
      }
    } catch (error) {
      console.log(error);
      showMessage("Creation failed!", "danger");
    }
  };

  return (
    <div className="container">
      <div className="card mt-5">
        <div className="card-header">
          <h3>Onboarding Form</h3>
        </div>
        <div className="container mt-4">
          <div className="row g-3">
            {/* VIP Clients */}
            <div className="col-6 col-md-3">
              <div className="card text-center shadow-sm small-card">
                <div className="card-body">
                  <h6 className="card-title mb-1">VIP Clients</h6>
                  <p className="fs-5 fw-bold mb-0">{vipCount}</p>
                </div>
              </div>
            </div>
            {/* Standard Clients */}
            <div className="col-6 col-md-3">
              <div className="card text-center shadow-sm small-card">
                <div className="card-body">
                  <h6 className="card-title mb-1">Standard Clients</h6>
                  <p className="fs-5 fw-bold mb-0">{standardCount}</p>
                </div>
              </div>
            </div>
            {/* Collectors */}
            <div className="col-6 col-md-3">
              <div className="card text-center shadow-sm small-card">
                <div className="card-body">
                  <h6 className="card-title mb-1">Collectors</h6>
                  <p className="fs-5 fw-bold mb-0">{collectorCount}</p>
                </div>
              </div>
            </div>
            {/* Imad Clients */}
            <div className="col-6 col-md-3">
              <div className="card text-center shadow-sm small-card">
                <div className="card-body">
                  <h6 className="card-title mb-1">Imad Clients</h6>
                  <p className="fs-5 fw-bold mb-0">{imadCount}</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="card text-center shadow-sm small-card">
                <div className="card-body">
                  <h6 className="card-title mb-1">NMC Clients</h6>
                  <p className="fs-5 fw-bold mb-0">{nmcCount}</p>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-3">
              <div className="card text-center shadow-sm small-card">
                <div className="card-body">
                  <h6 className="card-title mb-1">Special Clients</h6>
                  <p className="fs-5 fw-bold mb-0">{specialCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6 form-group">
                <label>Client Name:</label>
                <Select
                  className="search-line"
                  placeholder="Choose Client"
                  options={clientOptions}
                  onChange={handleClientChange}
                  filterOption={customFilter}
                  isSearchable={true}
                  value={client}
                />
              </div>
              {client && (
                <div className="col-md-6 form-group">
                  <label>Username:</label>
                  <Select
                    className="search-line"
                    placeholder="Choose Username"
                    options={usernames}
                    onChange={(opt) => {
                      setUsername(opt);
                      setSubscriptionId(opt.value);
                    }}
                    isSearchable={true}
                  />
                </div>
              )}
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label>Payment Method:</label>
                <Select
                  className="search-line"
                  placeholder="Choose Option"
                  options={paymentOptions}
                  onChange={(opt) => setPaymentMethod(opt.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Phone:</label>
                <input
                  className="form-control"
                  type="text"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label>Previous Reseller:</label>
                <input
                  className="form-control"
                  type="text"
                  value={Previous}
                  onChange={(e) => setPrevious(e.target.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Referral:</label>
                <input
                  className="form-control"
                  type="text"
                  value={Referral}
                  onChange={(e) => setReferral(e.target.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Location:</label>
                <input
                  className="form-control"
                  type="text"
                  value={Location}
                  onChange={(e) => setLocation(e.target.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Recipient:</label>
                <input
                  className="form-control"
                  type="text"
                  value={Recipient}
                  onChange={(e) => setRecipient(e.target.value)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>Notes:</label>
                <input
                  className="form-control"
                  type="text"
                  value={Note}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label>Expiration Day:</label>
                <Select
                  className="search-line"
                  placeholder="Choose Expiration Day"
                  options={Array.from({ length: 27 }, (_, i) => ({
                    label: (i + 2).toString(), // days between 2 and 28
                    value: i + 2,
                  }))}
                  onChange={(selectedOption) => {
                    setExpirationDay(
                      selectedOption ? selectedOption.value : null
                    ); // Add check for null
                  }}
                  value={
                    expirationDay
                      ? {
                          label: expirationDay.toString(),
                          value: expirationDay,
                        }
                      : null
                  } // Ensure null state
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-6 form-group">
                <label>App Installed:</label>
                <input
                  type="checkbox"
                  checked={appInstalled}
                  onChange={(e) => setAppInstalled(e.target.checked)}
                />
              </div>
              <div className="col-md-6 form-group">
                <label>WhatsApp Proof:</label>
                <input
                  type="checkbox"
                  checked={whatsappProof}
                  onChange={(e) => setWhatsappProof(e.target.checked)}
                />
              </div>
            </div>

            <button
              className="btn btn-primary"
              type="submit"
              disabled={!validate()}
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      {message && (
        <div
          className={`alert alert-${message.type} alert-dismissible fade show`}
          role="alert"
          style={{
            position: "fixed",
            bottom: "10px",
            right: "10px",
            zIndex: "9999",
          }}
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default OnboardingAdd;
