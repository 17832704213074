import React, { useState, useEffect, useContext } from "react";
import Axios from "axios";
import { API_URL } from "../../components/api";
import AuthContext from "../../context/AuthContext";
import "./MobileNotification.css";

function Notification(props) {
  const [EngTitle, setEngTitle] = useState("");
  const [ArTitle, setArTitle] = useState("");
  const [EngMessage, setEngMessage] = useState("");
  const [ArMessage, setArMessage] = useState("");
  const [smsText, setSmsText] = useState("");
  const [notificationType, setNotificationType] = useState("Notification");
  const [message, setMessage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  let authTokens = JSON.parse(localStorage.getItem("authTokens"));
  const { user } = useContext(AuthContext);

  // Fetch initial data to determine if notifications should be shown
  useEffect(() => {
    const fetchNotificationStatus = async () => {
      try {
        const response = await Axios.get(
          `${API_URL}/accounting/info/notification/`,
          {
            params: {
              userdealer: user.username,
              sub_id: props.sub_id,
            },
            headers: {
              Authorization: `Bearer ${authTokens.access}`,
            },
          }
        );
        setShowNotification(response.data.oss_user_exists); // true or false
        setPhoneNumber(response.data.phone_number);
        // If notification shouldn't be shown, set SMS as default
        if (!response.data.oss_user_exists) {
          setNotificationType("SMS");
        }
      } catch (error) {
        console.error("Failed to fetch notification status", error);
      }
    };

    fetchNotificationStatus();
  }, [authTokens.access, props.sub_id, user.username]);

  const showMessage = (message, type = "success") => {
    setMessage({ text: message, type });
    if (type === "success") {
      setTimeout(() => {
        setMessage(null);
      }, 4000);
    }
  };

  const handleEngTitleChange = (event) => {
    setEngTitle(event.target.value);
  };

  const handleEngMessageChange = (event) => {
    setEngMessage(event.target.value);
  };

  const handleArTitleChange = (event) => {
    setArTitle(event.target.value);
  };

  const handleArMessageChange = (event) => {
    setArMessage(event.target.value);
  };

  const handleSmsTextChange = (event) => {
    setSmsText(event.target.value.slice(0, 65)); // Ensure max 65 characters
  };

  const handleNotificationTypeChange = (event) => {
    setNotificationType(event.target.value);
  };

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (showNotification && notificationType === "Notification") {
      if (!EngTitle || !ArTitle || !EngMessage || !ArMessage) {
        showMessage("All Notification fields are required.", "danger");
        return;
      }
    }

    if (notificationType === "SMS" && !smsText) {
      showMessage(
        "SMS text is required and should be within 65 characters.",
        "danger"
      );
      return;
    }

    const requestData = {
      userdealer: user.username,
      sub_id: props.sub_id,
      phone_number: phoneNumber, // Include the phone number in the request
    };

    if (showNotification && notificationType === "Notification") {
      requestData.EngTitle = EngTitle;
      requestData.EngMessage = EngMessage;
      requestData.ArTitle = ArTitle;
      requestData.ArMessage = ArMessage;
      requestData.type = "Notification";
    }

    if (notificationType === "SMS") {
      requestData.SmsText = smsText;
      requestData.type = "SMS";
    }

    try {
      const response = await Axios.post(
        `${API_URL}/accounting/info/notification/`,
        requestData,
        {
          headers: {
            Authorization: `Bearer ${authTokens.access}`,
          },
        }
      );

      // Extract the message returned from the backend
      const responseMessage =
        response.data || `${notificationType} sent successfully!`;
      showMessage(responseMessage, "success");
    } catch (error) {
      // Extract the error message if available, otherwise provide a default error message
      const errorMessage =
        error.response?.data || `${notificationType} sending failed!`;
      showMessage(errorMessage, "danger");
    }
  };

  return (
    <div className="note-container">
      {showNotification && (
        <>
          <div className="note-row">
            <h5 className="note-heading">Notification Type</h5>
            <select
              className="note-select"
              onChange={handleNotificationTypeChange}
              value={notificationType}
            >
              <option value="Notification">Notification</option>
              <option value="SMS">SMS</option>
            </select>
          </div>
        </>
      )}

      {showNotification && notificationType === "Notification" && (
        <>
          <div className="note-row">
            <h5 className="note-heading">English Title</h5>
            <textarea
              className="note-textarea"
              placeholder="Enter message title"
              onChange={handleEngTitleChange}
              value={EngTitle}
              rows={2}
              required
            />
          </div>
          <div className="note-row">
            <h5 className="note-heading">English Text</h5>
            <textarea
              className="note-textarea"
              placeholder="Enter message text"
              onChange={handleEngMessageChange}
              value={EngMessage}
              rows={4}
              required
            />
          </div>
          <div className="note-row">
            <h5 className="note-heading">Title Arabic</h5>
            <textarea
              className="note-textarea"
              placeholder="Enter message title in Arabic"
              onChange={handleArTitleChange}
              value={ArTitle}
              rows={2}
              required
            />
          </div>
          <div className="note-row">
            <h5 className="note-heading">Text Arabic</h5>
            <textarea
              className="note-textarea"
              placeholder="Enter message text in Arabic"
              onChange={handleArMessageChange}
              value={ArMessage}
              rows={4}
              required
            />
          </div>
        </>
      )}

      {notificationType === "SMS" && (
        <>
          <div className="note-row">
            <h5 className="note-heading">Phone Number</h5>
            <input
              className="note-input"
              type="text"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={handlePhoneNumberChange}
              required
            />
          </div>

          <div className="note-row">
            <h5 className="note-heading">
              Arabic SMS Text (Max 65 characters)
            </h5>
            <textarea
              className="note-textarea"
              placeholder="Enter SMS text in Arabic"
              onChange={handleSmsTextChange}
              value={smsText}
              rows={2}
              maxLength={65}
              required
            />
            <p>{smsText.length}/65 characters</p>
          </div>
        </>
      )}

      <button className="note-submit-button" onClick={handleSubmit}>
        Submit
      </button>
      {message && (
        <div
          className={`alert alert-${message.type} note-message`}
          role="alert"
        >
          {message.text}
        </div>
      )}
    </div>
  );
}

export default Notification;
